import React, { useState } from "react";

import "../css/all.css";
import "../css/style.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import SimpleImageSlider from "react-simple-image-slider";

const AccordionWithPhotos = ({ photos }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div className="accordion-item mb-2">
        <h2 className="accordion-header" id="headingUserSubmittedPhotos">
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#UserSubmittedPhotos"
            aria-expanded="true"
            aria-controls="UserSubmittedPhotos"
          >
            User Submitted Photos
          </button>
        </h2>
        <div
          id="UserSubmittedPhotos"
          className="accordion-collapse collapse show"
          aria-labelledby="headingUserSubmittedPhotos"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body photo-post">
            <div className="row g-3">
              {photos?.map((photo, index) => (
                <div key={index} className="col-sm-6 col-md-6 col-lg-3">
                  <figure className="m-0" onClick={openModal}>
                    <img
                      src={photo?.url}
                      alt="Photo"
                      className="img-fluid"
                      style={{ cursor: "pointer" }}
                    />
                  </figure>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="slider-modal">
          <button className="image-gallary-button" onClick={closeModal} >Close</button>
          <SimpleImageSlider
            width={896}
            height={504}
            images={photos}
            showBullets={true}
            showNavs={true}
          />
        </div>
      )}
    </div>
  );
};

export default AccordionWithPhotos;
