import React, { useState } from 'react'
import { IoCloseCircleSharp } from "react-icons/io5";

function FavPopup({closefn, FavList, ID, id, setIsLoading}) {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const [favList, setFavList] = useState("");
  const [title, setTitle] = useState("");

  const handleCreateList = async()=>{
    setIsLoading(true);
    const response = await fetch(`${backendUrl}/api/favFood/add`,{
      method: 'POST',
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({title: title,user_id:ID})
    })
    setIsLoading(false);
    closefn(false);
  }

  const handleAddToList = async()=>{
    setIsLoading(true);
    const response = await fetch(`${backendUrl}/api/favFood/update/${favList}`,{
      method: 'PUT',
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ newFoodId : id })
    })
    setIsLoading(false);
    closefn(false);
  }

  return (
    <div className="popupModal" style={{zIndex: "999"}}>
      <div className='card' style={{backgroundColor: "lightpink", padding: "2rem 3rem"}}>
        <div>
        <IoCloseCircleSharp 
          className='closebtn'
          onClick={()=>closefn(false)} 
          fontSize={30}
        />
        </div>
        <h4 style={{textAlign: "center"}}>Add to your Favorite List</h4>
        <div className='popup-main'>
          <select value={favList} onChange={(e) => setFavList(e.target.value)} >
          <option value="" disabled>Choose your list</option>
            {FavList && (
              FavList.map((fav) => (
                <option key={fav._id} value={fav._id}>{fav.title}</option>
              ))
            )}
          </select>
          <button onClick={handleAddToList} style={{backgroundColor: "lightgreen"}}>Add</button>
        </div>
        <p style={{textAlign: "center", margin: "1rem"}}> ========= OR =========</p>
        <div>
          <input value={title} onChange={(e)=>setTitle(e.target.value)} style={{margin: "1rem"}} type="text" placeholder=' Create new List'/>
          <button onClick={handleCreateList} style={{backgroundColor: "lightgreen"}}>Create</button>
        </div>
      </div>
    </div>
  )
}

export default FavPopup