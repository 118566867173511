import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import logo from "../../images/logo-inner.png";
import profile from "../../images/profile.png";
import FOOTER from "../../component/FOOTER";
import HEADER from "../../component/HEADER";

function Blog() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [blogData, setBlogData] = useState([]);

  const navigate = useNavigate();

  const showDetails = (slug, id)=>{
    navigate(`/blog-details/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  //fetch blog data
  useEffect(() => {
    const fetchAllBlogs = async () => {
      const response = await fetch(`${backendUrl}/api/blog/all`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setBlogData(resData.data);
      console.log(resData.data);
    };

    fetchAllBlogs()
  }, []);

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink className="theme-color navlink">Blog</NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="blog-sec theme-bg layout_padding_2" style={{fontFamily:""}}>
        <div className="container">
          <div className="row g-4 align-items-center blog-box">
            {blogData?.filter(blog => (blog.status === 1)).map( blogs => (
              <div key={blogs._id} className="col-md-4 h-100 ">
                  <div className="theme-bg-white p-3 h-100 blog-list">
                    <figure onClick={()=>showDetails(blogs.slug, blogs._id)} style={{cursor: "pointer"}}>
                      <img
                        src={blogs.image}
                        alt="Blog"
                        className="img-fluid"
                      />
                      <span className="date text-center">
                      {blogs?.createdAt.substr(5,5)}
                      <br />
                      {blogs?.createdAt.substr(0,4)}
                      </span>
                    </figure>
                    <h3 className="mt-3">{blogs.title}</h3>
                    {/* <span className="mb-1">{blogs.status}</span> */}
                    <p>
                    {parse(`${blogs.description.substr(0,36)} ...`)}
                    </p>
                    <div className="theme-color Cpointer"
                      onClick={()=>showDetails(blogs.slug, blogs._id)}
                    >
                      Load More
                    </div>

                  </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default Blog;
