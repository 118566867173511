import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import { FiAlignJustify } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import logo from "../../images/logo-inner.png";
import profile from "../../images/profile.png";
import { MyContext } from "../../MyContext";
import FOOTER from "../../component/FOOTER";

function Profile() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userProfileData, setUserProfileData } = useContext(MyContext);

  const navigate = useNavigate();

  const { ID } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [toggleSidebar, setToggleSidebar] = useState(false);

  useEffect(() => {
    const userLogin = async () => {
      const response = await fetch(`${backendUrl}/api/user/profile/${ID}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const responseData = await response.json();
      setUserData(responseData.data);
      setUserProfileData(responseData.data);
      localStorage.setItem("user_data", JSON.stringify(responseData.data._id));
      // console.log(responseData.data)
      setLoading(false);
    };
    userLogin();
  }, [ID]);

  // useEffect(()=>{
  //   const isLogin = async()=>{
  //     const response = await fetch(`${backendUrl}/api/user/cookies`,{
  //       method: 'GET',
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       }
  //     })

  //     const Allcookies = await response.json();
  //     if(Allcookies.message === 'cookies not found'){
  //       navigate('/');
  //     }
  //   }
  //   isLogin();
  // },[])

  const handleLogout = async (e) => {
    e.preventDefault();
    localStorage.setItem( "user_data", '');
    setUserProfileData([]);
    navigate("/");

    // try {
    //   const response = await fetch(`${backendUrl}/api/user/logout`, {
    //     method: 'POST',
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     }
    //   });

    //   const responesData = await response.json();

    //   console.log(responesData)

    //   if (responesData.message === "User logged out") {
    //     navigate('/');
    //   }

    // } catch (error) {
    //   console.log(error)
    // }
  };

  return (
    <div style={{ fontFamily: "Arimo" }}>
      <header className="header_section" id="hdr-section">
        <section className="header_area inner-page-header">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <NavLink className="navbar-brand" to="/">
                <img
                  src={logo}
                  alt="Logo"
                  className="d-none d-lg-inline-block"
                  width={274}
                />
                <img
                  src={logo}
                  alt="Logo"
                  className="d-lg-none mob-logo"
                  width={180}
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto user-pro">
                  <li className="nav-item">
                    <span>
                      {loading === true ? (
                        "Loading..."
                      ) : (
                        <img
                          src={userData?.profile_img_url || profile}
                          alt="profile"
                          className="img-fluid"
                        />
                      )}
                    </span>
                    <div className="dropdown">
                      <button
                        className="btn btn-sm theme-bg-white dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {userData?.user_name
                          ? userData?.user_name
                          : "User Name"}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <button className="dropdown-item">My Account</button>
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="dropdown-item"
                          >
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </header>

      {/* ======= Sidebar ======= */}

      <aside
        className="sidebar"
        style={{ left: toggleSidebar ? "-300px" : "0", marginTop: "25px"}}
      >
        <ul className="sidebar-nav">
          <li className="nav-item">
            <div className="col-12 profile">
              <div className="prof-pic">
                <img
                  src={userData?.profile_img_url || profile}
                  alt="profile"
                  className="img-fluid"
                />
              </div>
              <div className="mt-3 mt-md-4 text-center">
                <h3 className="">
                  {userData?.user_name
                    ? userData?.user_name
                    : "User Name"}
                </h3>
              </div>
            </div>
          </li>
          <li className="nav-item mt-3">
            <NavLink className="nav-link collapsed active">
              <i className="fa-solid fa-user" />
              <span>Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/edit-profile/${ID}`} className="nav-link collapsed">
              <i className="fa-solid fa-pen" />
              <span>Edit Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/favorite-food/${ID}`} className="nav-link collapsed">
              <i className="fa-solid fa-utensils" />
              <span>Favorite Food</span>
            </NavLink>
          </li>
        </ul>
      </aside>

      {/* End Sidebar*/}

      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink className="navlink theme-color">Profile</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to={`/edit-profile/${ID}`} className="navlink">
                Edit Profile
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to={`/favorite-food/${ID}`} className="navlink">
                Favorite Food
              </NavLink>
            </div>
          </div>
          <div onClick={()=>setToggleSidebar(!toggleSidebar)}
            className="toggle-button" 
            style={{position: "absolute", cursor: "pointer", zIndex: "99",left: "0", margin: "20px 0"}}>
            {toggleSidebar ?  <FiAlignJustify fontSize={30}/> : <RxCross2 fontSize={30}/> }
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="blog-sec theme-bg layout_padding_2 profileSec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10 col-md-8 col-lg-6">
              <div className="row">
                {loading === true ? (
                  "loading ..."
                ) : (
                  <div className="col-12 profile">
                    <div className="prof-pic">
                      <img
                        src={userData?.profile_img_url || profile}
                        alt="profile"
                        className="img-fluid"
                      />
                    </div>
                    <div className="mt-3 mt-md-4 text-center">
                      <h3 className="">
                        {userData?.user_name
                          ? userData?.user_name
                          : "User Name"}
                      </h3>
                      <p>
                        <i className="fa-solid fa-location-dot" />{" "}
                        {userData?.address
                          ? userData?.address?.address1
                          : "User Address"}
                        <br />
                        Member since {userData?.createdAt?.slice(0, 10)}
                      </p>
                      {userData?.phone_no && (
                        <p>
                          Phone : {userData?.phone_no?.prefix}{" "}
                          {userData?.phone_no?.phone}
                        </p>
                      )}
                      {userData?.social_media?.facebook && (
                        <p>Facebook : {userData?.social_media?.facebook}</p>
                      )}
                      {userData?.social_media?.instagram && (
                        <p>Instagram : {userData?.social_media?.instagram}</p>
                      )}
                      {userData?.social_media?.youTube && (
                        <p>YouTube : {userData?.social_media?.youTube}</p>
                      )}
                      {userData?.social_media?.tikTok && (
                        <p>TikTok : {userData?.social_media?.tikTok}</p>
                      )}
                      {userData?.social_media?.twitter && (
                        <p>Twitter : {userData?.social_media?.twitter}</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-8 m-auto prof-dtls mt-3 mt-md-5">
              <div className="row">
                <div className="col-md-6 col-lg-3 text-center">
                  <p>Favorites</p>
                  <span>
                    <i className="fa-solid fa-heart" />
                  </span>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <p>Reviews</p>
                  <span>10</span>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <p>Followers</p>
                  <span>8</span>
                </div>
                <div className="col-md-6 col-lg-3 text-center">
                  <p>Following</p>
                  <span>15</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Privacy section */}

      {/* footer section */}
      <FOOTER />
    </div>
  );
}

export default Profile;
