import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import logo from "../../images/logo-inner.png";
import profile from "../../images/profile.png";
import blog1 from '../../images/blog1.png';
import post1 from '../../images/recentpost1.png';
import post2 from '../../images/recentpost2.png';
import post3 from '../../images/recentpost3.png';
import popular1 from '../../images/popular-1.png';
import popular2 from '../../images/popular-2.png';
import popular3 from '../../images/popular-3.png';
import FOOTER from "../../component/FOOTER";
import HEADER from "../../component/HEADER";

function BlogDetails() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { slug } = useParams();
  const location = useLocation();
  const { id } = location.state || {};
  const [popularHotel, setPopularHotel] = useState([]);

  const navigate = useNavigate();

  const [blogData, setBlogData] = useState();
  const [blogAllData, setAllBlogData] = useState([]);
  
  //fetch blog data
  useEffect(() => {
    const fetchBlog = async () => {
      const response = await fetch(`${backendUrl}/api/blog/all`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      const DATA = resData.data.filter(data => data._id === id);
      setBlogData(DATA[0]);
      setAllBlogData(resData.data);
      // console.log(resData.data);
    };

    fetchBlog()
  }, [id]);

  // fetch popular Hotel 
  useEffect(() => {
    const fetchPopularHotel = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/hotel/popular`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const resData = await response.json();
        setPopularHotel(resData.data);
        console.log(resData.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };
  
    fetchPopularHotel();
  }, []);

  const showDetails = (slug, id, key)=>{
    navigate(`/${key}/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/blog' className='navlink'>Blog</NavLink>
              <span className="theme-col-2"> / </span>
              <span className="theme-color">{blogData?.title}</span>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="blog-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row g-3 g-md-2 g-lg-4 blog-box blog-details-box">
            <div className="col-md-7 col-lg-7 col-xl-9">
              <div className="theme-bg-white p-3">
                <figure>
                  <img
                    src={blogData?.image}
                    alt="Blog"
                    className="img-fluid"
                  />
                  <span className="date text-center">
                    {blogData?.createdAt?.substr(5,5)}
                    <br />
                    {blogData?.createdAt?.substr(0,4)}
                  </span>
                </figure>
                <h3 className="mt-3">{blogData?.title}</h3>
                {/* <span className="mb-1">By John Doe</span> */}
                <p className="mb-3">
                {parse(`${blogData?.description}`)}
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 col-xl-3 blog-sidebar">
              <div className="theme-bg-white p-3">
                <div className="sort-cnt pb-2">
                  <h4>Recent Posts</h4>
                </div>
                {blogAllData?.map((blog) => (
                <div key={blog?._id} className="row g-0 mt-3" onClick={()=>showDetails(blog.slug, blog._id, "blog-details")} style={{cursor: "pointer"}}>
                  <div className="col-4 col-sm-3 col-md-4 col-lg-4">
                    <figure>
                      <img
                        src={blog?.image}
                        alt="Popular"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="col-8 col-sm-9 col-md-8 col-lg-8">
                    <div className="blog-cnt ps-2">
                      <span>{blog?.createdAt.substr(0,10)}</span>
                      <p>{blog?.title}</p>
                    </div>
                  </div>
                </div>
                ))}

              </div>
              <div className="theme-bg-white p-3 mt-4">
                <div className="sort-cnt pb-2">
                  <h4>Popular Restaurants</h4>
                </div>
                {popularHotel?.map((hotel) => (
                <div key={hotel?._id} className="row g-0 mt-3" onClick={()=>showDetails(hotel.slug, hotel._id, "listing-details")} style={{cursor: "pointer"}}>
                  <div className="col-4 col-sm-3 col-md-4 col-lg-4">
                    <figure>
                      <img
                        src={hotel?.featured_image}
                        alt="Popular"
                        className="img-fluid"
                      />
                    </figure>
                  </div>
                  <div className="col-8 col-sm-9 col-md-8 col-lg-8">
                    <div className="blog-cnt ps-2">
                      <span>{hotel?.updatedAt.substr(0,10)}</span>
                      <p>{hotel?.hotel_name.substr(0,25)}</p>
                    </div>
                  </div>
                </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default BlogDetails;
