import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { ImCross } from "react-icons/im";

import './css/all.css';
import './css/style.css';
import './css/responsive.css';
import './css/bootstrap.min.css';

import placeholderIMG from './images/placeholder.svg';
import logo from './images/logo.png';
import food1 from './images/food-1.png';
import food2 from './images/food-2.png';
import food3 from './images/food-3.png';
import food4 from './images/food-4.png';
import sec from "./images/sec-img.png";
import ScrollToTop from './component/ScrollToTop';
import FOOTER from './component/FOOTER';

import { MyContext } from "./MyContext";

function App() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userProfileData, setUserProfileData } = useContext(MyContext)
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [suggestion , setSuggestion] = useState('');
  const [popularFood, setPopularFood] = useState([]);
  const [popularHotel, setPopularHotel] = useState([]);
  const [selectedValue, setSelectedValue] = useState('Food');
  const [selectedItem, setSelectedItem] = useState(-1);

  // handle search submition for navigate to the hotel or food page 
  const handleSubmit = async (e) =>{
    e.preventDefault();

    if (searchValue.trim() !== '') {
      if(selectedValue === "Hotel"){
        navigate(`/listing/${searchValue}`);
      }else if(selectedValue === "Food"){
        navigate(`/food/${searchValue}`);
      }
    }else{
      if(selectedValue === "Hotel"){
        navigate(`/listing/all`);
      }else if(selectedValue === "Food"){
        navigate(`/food/all`);
      }
    }
  }

  // fetch popular food 
  useEffect(()=>{
    const PopularFood = async()=>{
      const response = await fetch(`${backendUrl}/api/food/popular`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })

      const resData = await response.json();
      // console.log("PopularFood : ", resData);
      setPopularFood(resData.data);
    }

    PopularFood();
  },[])

  // fetch popular Hotel 
  useEffect(() => {
    const fetchPopularHotel = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/hotel/popular`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const resData = await response.json();
        setPopularHotel(resData.data);
        // console.log(resData.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };
  
    fetchPopularHotel();
  }, []);
  

  // call food or hotel for search suggestion 
  useEffect(()=>{
    const SearchItem = async()=>{
      const response = await fetch(`${backendUrl}/api/${selectedValue.toLowerCase()}/search/${searchValue}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })

      const resData = await response.json();
      // console.log(resData);
      setSuggestion(resData.data);
    }

    if(searchValue !== ''){
      SearchItem();
    }
  },[searchValue])

  // handleKeyDown on search suggestion
  const handleKeyDown = (e) =>{
    if(e.key === "ArrowUp" && selectedItem > 0){
      setSelectedItem(prev => prev - 1);
    }else if(e.key === "ArrowDown" && selectedItem < 5){
      setSelectedItem(prev => prev + 1);
    }else if(e.key === "Enter" && selectedItem >= 0){
      if(selectedValue === "Hotel"){
        navigate(`/listing/${suggestion[selectedItem].hotel_name}`);
      }else if(selectedValue === "Food"){
        navigate(`/food/${suggestion[selectedItem].title}`);
      }
    }
  }

  const showDetails = (item,slug, id)=>{
    if(item === "food"){
      navigate(`/food-details/${slug}`,{
        state: { 
          id: id
        }
      })
    }else{
      navigate(`/listing-details/${slug}`,{
        state: { 
          id: id
        }
      })
    }
  }

  const handleLogoutByContext = (e)=>{
    e.preventDefault();
    localStorage.setItem( "user_data", '');
    setUserProfileData([])
  }

  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }

  return (
    <>
      {/* Hero Section*/}
      <section id="home" className="hero-section">
        {/* header section strats */}
        <header className="header_section home-page-header">
          <div className="header_area">
            <nav className="navbar navbar-expand-lg homenavbar">
              <div className="container">
                <NavLink className="navbar-brand" to='/'>
                  <img
                    src={logo}
                    alt="Logo"
                    className="d-none d-lg-inline-block"
                    width={274}
                  />
                  <img
                    src={logo}
                    alt="Logo"
                    className="d-lg-none mob-logo"
                    width={180}
                  />
                </NavLink>
                {userProfileData?.user_name ? 
                <>
                <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav ms-auto user-pro">
                    <li className="nav-item">
                      <span style={{marginRight: "10px"}}>
                        <img
                          src={userProfileData?.profile_img_url }
                          alt="profile"
                          className="img-fluid"
                        />
                      </span>
                      <div className="dropdown">
                        <button
                          className="btn btn-sm theme-bg-white dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {userProfileData?.user_name ? userProfileData?.user_name : 'User Name' }
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button className="dropdown-item" onClick={()=>navigate(`/profile/${userProfileData?._id}`)}>
                              My Account
                            </button>
                          </li>
                          <li>
                            <button onClick={handleLogoutByContext}  className="dropdown-item">
                              Log Out
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                </>
                : 
                <>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="homenavbar collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto log-sign" >
                      <li className="nav-item">
                        <NavLink to='/login'
                          className="nav-link theme-hover-col"
                        >
                          Log in
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to='/signup'
                          className="nav-link theme-hover-col"
                        >
                          Sign up
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
                }
              </div>
            </nav>
          </div>
        </header>
        {/* end header section */}
        <div className="d-flex align-items-center h-75">
          <div className="container">
            <div className="row search-area">
              <div className="col-md-10 col-lg-10 m-auto">
                <form onSubmit={handleSubmit}>
                  <div className="input-group">
                    <input
                      type="text"
                      value={searchValue}
                      onChange={(e)=>setSearchValue(e.target.value)}
                      onKeyDown={handleKeyDown}
                      style={{paddingLeft: "130px"}}
                      className="form-control"
                      placeholder="Search for anything"
                      aria-label="Dollar amount (with dot and two decimal places)"
                    />
                      <select style={{
                        zIndex: "99",
                        borderRadius: "5px 0 0 5px",
                        position: "absolute",
                        padding: "15.5px 5px",
                        backgroundColor: "white",
                        border: "none",
                        boxShadow: "none",
                        outline: "none",
                        fontSize: "large",
                        textAlign: "center"
                        }} value={selectedValue} onChange={(e)=>setSelectedValue(e.target.value)}>
                        <option value="Food">Item</option>
                        <option value="Hotel">Venue</option>
                      </select>
          
                    {searchValue !== "" &&
                      <span
                      style={{padding: "15px 20px", backgroundColor: "white", cursor: "pointer"}} 
                      onClick={()=>setSearchValue('')}>
                        <ImCross />
                      </span>
                    }

                    <span className="input-group-text" onClick={handleSubmit}>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                  </div>
                </form>
                
                {searchValue !== '' && suggestion.length !== 0 &&(
                  <div className='search-suggetion' >
                  {suggestion.length !== 0 && (
                    suggestion.slice(0,5).map((sugg, index) =>(
                      <NavLink className='navlink' to={selectedValue === "Hotel" ? `/listing/${sugg.hotel_name}` : `/food/${sugg.title}`}>
                        <p key={sugg._id} className={selectedItem === index ? 'searchActive' : ''}>
                          {sugg.title ? sugg.title : sugg.hotel_name}  
                        </p>
                      </NavLink>
                    )
                  ))}
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Hero section */}
      {/* Start Explore Epcot’s Food section */}
      <section className="explore-sec layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading_container">
                <h1 className="theme-color d-inline-block">
                  Explore Epcot’s Food
                </h1>
                <h2 className="theme-col-2">&amp; Wine Festival</h2>
                <p className="theme-col-2 mt-3">
                  Thousands of MSF users have rated and reviewed the food at
                  Disney’s Food &amp; Wine Festival. Have a look at what they
                  choose as the <i>“best of the fest”</i>.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-3 mt-3 mt-md-4 mt-lg-5">
              <div className="food-img">
                <figure>
                  <img
                    src={food1}
                    alt="Food"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mt-md-4 mt-lg-5">
              <div className="food-img">
                <figure>
                  <img
                    src={food2}
                    alt="Food"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mt-md-4 mt-lg-5">
              <div className="food-img">
                <figure>
                  <img
                    src={food3}
                    alt="Food"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-3 mt-md-4 mt-lg-5">
              <div className="food-img">
                <figure>
                  <img
                    src={food4}
                    alt="Food"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Explore Epcot’s Food section */}
      {/* start Customer Say section */}
      <section className="customer-say-sec layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-8 m-auto">
              <div className="owl-carousel owl-theme customer-slider my-0 my-md-5">
                <div className="item text-center">
                  <p className="theme-col-2">
                    I have a son with a food allergy and MSF made it so easy to
                    filter and save the foods he was able to eat, where they
                    were located, how much they cost, and so much more!
                  </p>
                  <span className="theme-col-2">
                    <i>
                      <b>-Jess from Hoover, AL</b>
                    </i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Our Customer Say section */}
      {/* Start Snacks section */}
      <section className="snacks-sec layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading_container_2 text-center">
                <h1 className="theme-color d-inline-block">Snacks</h1>
                <h2 className="theme-col-2">MSF Users Love</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="owl-carousel owl-theme snacks-slider mt-5" style={{display: "flex", flexWrap: "wrap", gap : "55px", justifyContent : "center"}}>
                {popularFood &&
                  popularFood?.map((food)=> (
                    <div key={food._id} className="item text-center" 
                      onClick={()=>showDetails("food", food.slug, food._id)}>
                      <NavLink >
                      <figure style={{borderRadius: "5px"}}>
                        <img src={food.featured_image || placeholderIMG} alt="Snacks" width={400} height={150}/>
                      </figure>
                      </NavLink>
                      <p className="theme-col-2">{food.title.slice(0,15)}...</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Snacks section */}
      {/* Start Content section */}
      <section className="content-sec layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 text-center">
              <figure>
                <img
                  src={sec}
                  alt="content Images"
                  className="img-fluid"
                />
              </figure>
            </div>
            <div className="col-lg-4 mt-5mt-lg-0">
              <div className="cnt-sec">
                <p className="text-white">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                </p>
                <p className="text-white">
                  {" "}
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea.{" "}
                </p>
              </div>
            </div>
            <div className="col-lg-4 mt-5mt-lg-0">
              <div className="cnt-sec">
                <p className="text-white">
                  {" "}
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
                </p>
                <p className="text-white">
                  {" "}
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Content section section  */}
      {/* Start Quote section */}
      <section className="quote-sec layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-7 m-auto">
              <div className="cnt-sec text-center">
                <p className="theme-col-2">
                  “Fancy quote to inspire the millions of visitors this app will
                  bring. Goes somewhere on this page. Maybe twice.”
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Quote section  */}
      {/* Start Popular Restaurants section */}
      <section className="popular-sec layout_padding pt-0">
        <div className="popular-sec-top theme-bg-2 layout_padding pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="heading_container_3 text-center">
                  <h1 className="text-white d-inline-block">
                    Popular Restaurants
                  </h1>
                  <h2 className="text-white">Discover the best food</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* popular Hotel section filter by rating */}
        <div className="container" style={{marginTop: "-15rem"}}>
          <div className="row popular-cnt-area">
            <div className="col-12">
              <div className="popular-cnt theme-bg " style={{padding: "3rem"}}>
                <div className="row g-3">
                  {popularHotel && (
                    popularHotel.map((hotel)=>(
                      <div key={hotel._id} className="col-md-4"
                       onClick={()=>showDetails("hotel",hotel.slug, hotel._id)}
                      >
                        <NavLink className='navlink'>
                        <div className="theme-bg-white p-3 text-center h-100">
                          <figure>
                            <img
                              src={hotel?.featured_image || placeholderIMG}
                              alt="Popular"
                              className="img-fluid"
                            />
                          </figure>
                          <h3 className="theme-color mt-3">
                            {hotel.hotel_name}
                          </h3>
                          <p className="theme-col-2">
                          {hotel.address}
                          </p>
                        </div>
                      </NavLink>
                    </div>
                    ))
                  )}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Popular Restaurants section  */}
      {/* footer section */}
      <FOOTER />
      {/* go to top button  */}
      <ScrollToTop/>
    </>
  );
}

export default App;
