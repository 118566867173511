import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";
import "./profile-style.css";

// import food1 from '../../images/food-1.png';
// import food2 from '../../images/food-2.png';
// import food3 from '../../images/food-3.png';
// import logo from "../../images/logo-inner.png";
import trashCan from './red-trash-can-icon.svg';

import { MdDelete } from "react-icons/md";
import { FiAlignJustify } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { RxCrossCircled } from "react-icons/rx";
import profile from "../../images/profile.png";
import { MyContext } from "../../MyContext";
import FOOTER from "../../component/FOOTER";
import ScrollToTop from "../../component/ScrollToTop";
import HEADER from "../../component/HEADER";
import Loader from "../../component/Loader/Loader";

const FavFood = () => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userProfileData, setUserProfileData } = useContext(MyContext);
  const navigate = useNavigate();

  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [FavList , setFavList] = useState([]);
  const [activeTab, setActiveTab] = useState("");
  const [delFavId, setDelFavId] = useState("");
  const [showPopup, setShowPopUP] = useState(false);
  const [favListChange, setFavListChange] = useState(false);
  const [isLoading , setIsLoading] = useState(true);

  useEffect(()=>{
    setIsLoading(true);
    const fetchFavList = async()=>{
      const response = await fetch(`${backendUrl}/api/favFood/all/${userProfileData?._id}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      setFavList(resData.data);
      setActiveTab(activeTab === "" ? resData?.data[0]?.title : activeTab);
      setDelFavId(resData.data ? resData?.data[0]?._id : "");
      setIsLoading(false);
    }
    fetchFavList();
  },[userProfileData, favListChange])

  const delFavFoodList = (FID) =>{
    setShowPopUP(true);
    setDelFavId(FID);
  }

  const handleRemoveFood = async (foodID) => {
    console.log(`LID : ${delFavId} and ${foodID}`);

    const response = await fetch(`${backendUrl}/api/favFood/updateFood/${delFavId}`,{
      method: 'PUT',
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({FoodId : foodID})
    });

    const resData = await response.json();
    console.log("update Food: ", resData);

    setFavListChange(!favListChange);
  }

  const handleDelFavList = async() => {
    setIsLoading(true);
    const response = await fetch(`${backendUrl}/api/favFood/delete/${delFavId}`,{
      method: 'DELETE',
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
    const resData = await response.json();
    setActiveTab("");
    setShowPopUP(false);
    setFavListChange(!favListChange);
    setIsLoading(false);
  }
  
  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }

  const handleLogoutByContext = (e)=>{
    e.preventDefault();
    localStorage.removeItem("user_data");
    setUserProfileData([]);
    navigate("/");
  }

  const showDetails = (slug, id)=>{
    navigate(`/food-details/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  return (
    <div style={{ fontFamily: "Arimo" }}>
      {/* ======= Header ======= */}
      <HEADER/>
      {/* End Header */}
      {/* ======= Sidebar ======= */}

        <aside className="sidebar" style={{ left: toggleSidebar ? "-300px" : "0" }}>
          <ul className="sidebar-nav" >
              <li className="nav-item">
              <div className="col-12 profile">
              <div className="prof-pic">
                  <img
                    src={userProfileData?.profile_img_url || profile}
                    alt="profile"
                    className="img-fluid"
                  />
                </div>
                <div className="mt-3 mt-md-4 text-center">
                  <h3 className="">
                    {userProfileData?.user_name
                      ? userProfileData?.user_name
                      : "User Name"}
                  </h3>
                </div>
              </div>
              </li>
              <li className="nav-item mt-3">
                <NavLink to={`/profile/${userProfileData?._id}`} className="nav-link collapsed">
                  <i className="fa-solid fa-user" />
                  <span>Profile</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to={`/edit-profile/${userProfileData?._id}`} className="nav-link collapsed">
                  <i className="fa-solid fa-pen" />
                  <span>Edit Profile</span>
                </NavLink>
              </li>
              {/* End Dashboard Nav */}
              <li className="nav-item">
                <NavLink className="nav-link collapsed active">
                  <i className="fa-solid fa-utensils" />
                  <span>Favorite Food</span>
                </NavLink>
              </li>
            {/* End Dashboard Nav */}
          </ul>
        </aside>

      {/* End Sidebar*/}
      <main id="main" className="main theme-bg">
        <div className="pagetitle">
          <div onClick={()=>setToggleSidebar(!toggleSidebar)}
            className="toggle-button" 
            style={{position: "absolute", right: "25px", cursor: "pointer", zIndex: "99"}}>
            {toggleSidebar ?  <FiAlignJustify fontSize={30}/> : <RxCross2 fontSize={30}/> }
              
          </div>
          <h1>Favorite Food</h1>
          <nav>
            <ol className="breadcrumb">
              <NavLink to="/" className="nav-link breadcrumb-item">Home</NavLink>
              <NavLink to={`/profile/${userProfileData?._id}`} className="nav-link breadcrumb-item">Profile</NavLink>
              <NavLink className="nav-link breadcrumb-item active">Favorite Food</NavLink>
            </ol>
          </nav>
        </div>

        {/* End Page Title */}
        <section className="section profile">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pt-3">
                  {/* Bordered Tabs */}
                  <ul className="nav nav-tabs nav-tabs-bordered">
                    {FavList && FavList.map(FList =>
                    <li key={FList._id} className="nav-item" onClick={()=>setDelFavId(FList._id)}>
                      <button
                        className={activeTab === FList.title? "nav-link bg-red-500" : "nav-link"}
                        data-bs-toggle="tab"
                        data-bs-target={FList.title}
                        onClick={() => setActiveTab(FList.title)}
                      >
                        <i className="fa-solid fa-star" /> {FList.title}
                      </button>
                    </li>
                    )}
                  </ul>
                  {isLoading ? <Loader/> :
                  <div className="tab-content pt-2">
                    {FavList && FavList.map(FList =>
                    <div key={FList._id}
                      className={`tab-pane fade food-cat pt-3 show ${
                        activeTab === FList.title ? "active" : ""
                      }`}
                      id={FList.title}
                    >
                      <div className="row">
                        <div className="col-12 mb-2 mb-md-3 d-flex gap-2">
                          <h4>{FList.title}</h4> 
                          <MdDelete onClick={()=>delFavFoodList(FList._id)} fontSize={27} color="red" cursor="pointer"/>
                        </div>
                      </div>
                      <div className="row g-4 favorites-box">
                        {FList.foodDetails.map(food =>
                        <div 
                          style={{position: "relative"}}
                          key={food._id} 
                          className="col-md-4" 
                        >
                          <div style={{position: "absolute", right: "0.8rem"}}>
                            <RxCrossCircled color="red" onClick={()=>handleRemoveFood(food._id)}/>
                          </div>
                          <NavLink className="nav-link"  onClick={()=>showDetails(food.slug, food._id)} >
                            <div className="theme-bg p-3 h-100">
                              <figure>
                                <img
                                  src={food.featured_image}
                                  alt="Favorites"
                                  className="img-fluid"
                                />
                              </figure>
                              <h3 className="mt-3">{food.title}</h3>
                              {food?.description ?
                              <p className="theme-col-2 mb-1">
                                {isHTML(food?.description) 
                                  ? parse(food?.description.substring(0, 30))
                                  : `${food?.description?.substring(0, 30)} ...`}
                              </p>
                              : <p className="theme-col-2 mb-1">Demo Description ...</p>
                              }
                              <span className="mb-1">${food.price}</span>
                            </div>
                          </NavLink>
                        </div>
                        )}
                      </div>
                    </div>
                    )}
                  </div>
                  }
                  {/* End Bordered Tabs */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      {/* End #main */}

      {showPopup && (
        <div className="modal-overlay" onClick={()=>setShowPopUP(false)}>
          <div
            className="modal-contentss p-2"
            onClick={(e) => e.stopPropagation()} // Prevent closing on content click
          >
            <div className="modal-header">
              <h2>Delete <span style={{color: "red"}}>{activeTab}</span> List</h2>
              <button className="close-button" onClick={()=>setShowPopUP(false)}>
                &times;
              </button>
            </div>
            <hr />
            <div style={{display: "flex", justifyContent: "center", margin: "1rem"}}>
              <img src={trashCan} alt="trach-can" width={50}/>
            </div>
            <div className="modal-body text-center mb-2">
              Are you sure you want to delete this List?
            </div>
            <div className="modal-footers">
              <button className="btn-secondary" onClick={()=>setShowPopUP(false)}>
                Cancel
              </button>
              <button className="btn-primary" onClick={handleDelFavList}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}

      <FOOTER/>
      <ScrollToTop/>
    </div>
  );
};

export default FavFood;
