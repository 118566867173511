import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import parse from "html-react-parser";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import Logo from "../../images/logo-inner.png";
import FOOTER from "../../component/FOOTER";
import HEADER from "../../component/HEADER";

function Sitemap() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [sitemapData, setSitemapData] = useState([]);

  //fetch Tou data
  useEffect(() => {
    const fetchSitemap = async () => {
      const response = await fetch(`${backendUrl}/api/cms/all`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setSitemapData(resData.data.filter((item)=>item._id === "674896d23f98fbb20d657f12"));
      // console.log(resData.data);
    };

    fetchSitemap()
  }, []);

  return (
    <div style={{fontFamily:"Arimo"}}>
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/sitemap' className="navlink theme-color">
                Sitemap
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="privacy-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <div className="heading_container">
                <h2 className="theme-col-2">{sitemapData ? sitemapData[0]?.name : "Sitemap"}</h2>
                <p className="theme-col-2 mt-3">
                  {sitemapData[0] && parse(sitemapData[0]?.content[0])}
                </p>
              </div>
            </div>
          </div>

          {sitemapData[0]?.content
          ?.slice(1) // Skips the 0th index
          .map((item, index) => (
            <div key={index + 1} className="para mb-4">
              {index % 2 === 0 ? (
                <h4 className="theme-col-2">{parse(item)}</h4>
              ) : (
                <p className="theme-col-2 mt-3">{parse(item)}</p>
              )}
            </div>
          ))}
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default Sitemap;
