import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from 'react-router-dom';

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import logo from "../../images/logo-inner.png";
import FOOTER from "../../component/FOOTER";
import { MyContext } from "../../MyContext";
import HEADER from "../../component/HEADER";

function Contact() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { userProfileData, setUserProfileData } = useContext(MyContext)

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");

  const handleLogoutByContext = (e)=>{
    e.preventDefault();
    localStorage.removeItem("user_data");
    setUserProfileData([]);
    navigate("/");
  }

  const handleSubmit = async(event) => {
    event.preventDefault();
    const response = await fetch(`${backendUrl}/api/admin/message/add`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({email,name,message}),
    }
    );
    const resData = await response.json();
    if(resData.statusCode !== 200){
      return;
    }

    alert("Your Message has been submited!")
    setEmail("");
    setName("");
    setMessage("");
  }

  return (
    <div style={{fontFamily:"Arimo"}}>
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/contact-us' className="navlink theme-color">
                Contact
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="contact-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-md-10 col-lg-6 m-auto">
              <div className="heading_container text-center">
                <h2 className="theme-col-2">Contact Us</h2>
                <p className="theme-col-2 mt-3">
                  Still have questions? Maybe a comment you’d like to make?
                  Please use the form below to submit your inquiry.
                </p>
              </div>
            </div>
          </div>
          <div className="row h-100 justify-content-center align-items-center mt-3">
            <div className="col-md-6 col-lg-4">
              <div className="theme-bg-white p-4">
                <div className="login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="emailinput" className="form-label">
                        Email address
                      </label>
                      <input
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                        type="email"
                        className="form-control theme-bg"
                        id="email"
                        placeholder=""
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="nameinput" className="form-label">
                        Name
                      </label>
                      <input
                        value={name}
                        onChange={(e)=>setName(e.target.value)}
                        type="text"
                        className="form-control theme-bg"
                        id="nameinput"
                        placeholder=""
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="Comment" className="form-label">
                        Question or Comment
                      </label>
                      <textarea
                        value={message}
                        onChange={(e)=>setMessage(e.target.value)}
                        className="form-control theme-bg"
                        id="Comment"
                        rows={3}
                        defaultValue={""}
                      />
                    </div>
                    <div className="mb-0">
                      <button type="submit" className="cmn_btn btn btn-lg btn-bg w-100">
                        Send
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4 mt-md-5">
            <div className="col-md-10 col-lg-10 m-auto">
              <div className="heading_container text-center">
                <h2 className="theme-col-2">Frequently Asked Questions</h2>
              </div>
            </div>
          </div>
          <div className="row mt-4 mt-md-5">
            <div className="col-md-8 m-auto ask-question">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item mb-2">
                  <h2 className="accordion-header" id="headingappwork1">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#appwork1"
                      aria-expanded="false"
                      aria-controls="appwork1"
                    >
                      How does this app work?
                    </button>
                  </h2>
                  <div
                    id="appwork1"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingappwork1"
                    data-bs-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="accordion-body food-dtls-box">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-2">
                  <h2 className="accordion-header" id="headingappwork2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#appwork2"
                      aria-expanded="false"
                      aria-controls="appwork2"
                    >
                      How does this app work?
                    </button>
                  </h2>
                  <div
                    id="appwork2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingappwork2"
                    data-bs-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="accordion-body food-dtls-box">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-2">
                  <h2 className="accordion-header" id="headingappwork3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#appwork3"
                      aria-expanded="false"
                      aria-controls="appwork3"
                    >
                      How does this app work?
                    </button>
                  </h2>
                  <div
                    id="appwork3"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingappwork3"
                    data-bs-parent="#accordionExample"
                    style={{}}
                  >
                    <div className="accordion-body food-dtls-box">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default Contact;
