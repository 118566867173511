import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//Automatic go to the top if any page open in center
//A problem occure in both food details and listing details page by some id
//To solve this problem it created

const Relocation = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default Relocation;
