import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import '../../css/all.css';
import '../../css/style.css';
import '../../css/responsive.css';
import '../../css/bootstrap.min.css';

import logo from '../../images/logo-inner.png';
import mapImg from '../../images/map.png';
import placeholderIMG from '../../images/placeholder.svg';
import breakfast1 from '../../images/breakfast-1.png';
import profileImg from '../../images/profile.png';
import Loader from "../../component/Loader/Loader";
import FOOTER from "../../component/FOOTER";
import { MyContext } from "../../MyContext";
import HEADER from "../../component/HEADER";

function ListingDetails() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { userProfileData, setUserProfileData } = useContext(MyContext)
  const { slug } = useParams();
  const location = useLocation();
  const { id } = location.state || {};

  const [hotelData, setHotelData] = useState([]);
  const [hotelFood, setHotelFood] = useState([]);
  const [isLoading , setIsLoading] = useState(true);

  const handleLogoutByContext = (e)=>{
    e.preventDefault();
    localStorage.setItem( "user_data", '');
    setUserProfileData([]);
    navigate("/");
  }

  const filterCuisines = (cuisine, id) =>{
    navigate(`/listing/${cuisine}`,{
      state: { 
        id: id
      }
    });
  }

  const showDetails = (slug, id)=>{
    sessionStorage.setItem("foodDetailsId", id);
    navigate(`/food-details/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  useEffect(()=>{
    const fetchHotel = async()=>{
      const response = await fetch(`${backendUrl}/api/hotel/${id}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      console.log(resData);
      setHotelData(resData.data);
      setIsLoading(false)
    }

    fetchHotel();
  },[id])

  useEffect(()=>{
    const fetchHotelFood = async()=>{
      const response = await fetch(`${backendUrl}/api/food/hotel/${id}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      console.log(resData);
      setHotelFood(resData.data);
    }

    fetchHotelFood();
  },[id])

  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }
  
  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/listing/all' className="navlink theme-col-2">
               Venue
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/listing-details' className="navlink theme-color">
                {hotelData?.hotel_name}
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Filter section */}
      {isLoading ? <Loader/> :
      <section className="filter-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row sort-details">
                <div className="col-12">
                  <figure>
                    <img
                      src={ hotelData?.featured_image || placeholderIMG}
                      alt="Popular"
                      className="img-fluid"
                    />
                  </figure>
                  <div className="row sort-cnt align-items-center">
                    <div className="col-md-8">
                      <div className="h-100">
                        <h3 className="mt-3">{hotelData?.hotel_name}</h3>
                        <p className="theme-col-3">
                          <span className="theme-color">Open:</span> {hotelData?.opening} 
                        </p>
                        <span style={{fontSize: "20px"}} className="theme-color">Price : </span> 
                        {hotelData?.price?.map((prices, index) =>(
                          <span key={index} className="theme-col-3">{prices}{index < hotelData.price.length - 1 ? ', ' : ''}</span>
                        ))}
                      </div>
                      <br />
                      <p><span className="theme-color">Breakfast :</span> {hotelData?.breakfast_time}</p>
                      <p><span className="theme-color">Lunch :</span> {hotelData?.lunch_time}</p>
                      <p><span className="theme-color">Dinner :</span> {hotelData?.dinner_time}</p>
                    
                      
                    
                    </div>
                    <div className="col-md-4 text-start text-md-end">
                      <NavLink target="_blank"  to={hotelData?.url} className="navlink"
                      style={{
                        // backgroundColor: "blue",
                        marginRight: "1rem",
                      }}
                      >🌍 visit</NavLink>
                      <span className="ratting-dtls">
                        {hotelData?.rating} <i className="fa-solid fa-star" />
                      </span>
                      <span className="navlink theme-col-2">
                        <u className='navlink'>
                          <span> 2.3K </span> ratings
                        </u>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="theme-bg-white mt-4">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="p-2 p-lg-4 sort-cnt h-100">
                        <h4 className="mb-3">Description</h4>
                        {hotelData?.description ?
                          <p className="theme-col-2 mb-1">
                            {isHTML(hotelData?.description) 
                              ? parse(hotelData?.description)
                              : hotelData?.description }
                          </p>
                          : <p className="theme-col-2 mb-1">Demo Description ...</p>
                        }
                        <p className="theme-col-2">
                          {hotelData?.address}
                        </p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <figure className="map p-2 p-lg-4">
                        <img
                          src={mapImg}
                          alt="Popular"
                          className="img-fluid"
                        />
                      </figure>
                    </div>
                  </div>
                </div>

                <div className="col-md-12 mt-3">
                  <div>
                    <span style={{fontSize: "22px"}}>Cuisines : </span>
                      {hotelData?.cuisineDetails?.map((item,index) =>(
                        <span key={item._id} onClick={()=>filterCuisines(item.slug,item._id)} className="theme-color mb-3" style={{fontSize: "20px", cursor: "pointer"}}>{item.name} {index < hotelData?.cuisineDetails?.length - 1 ? ', ' : ''}</span>
                      ))}
                  </div>
                </div>
                
              </div>
              <div className="col-12 mt-3">
                <div className="row g-0 align-items-center">
                  <div className="col-md-9 col-lg-10">
                    <div className="filter-btn d-flex flex-wrap">
                      {hotelData?.typeDetails?.map((item)=>(
                        <NavLink
                        onClick={(event) => event.preventDefault()}
                        key={item._id} 
                        className='navlink' 
                        style={{width: "auto", padding: "0 15px"}}
                        > {item.name} </NavLink>
                      ))}
                    </div>
                  </div>
                  {/* <div className="col-md-3 col-lg-2">
                    <div className="filter-bar2 border-0 d-flex justify-content-start justify-content-lg-end">
                      <select
                        className="form-select"
                        id="filterselect"
                        aria-label="Default select example"
                      >
                        <option selected="">Filter</option>
                        <option value={1}>One</option>
                        <option value={2}>Two</option>
                        <option value={3}>Three</option>
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="col-12 mt-3">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="breakfast-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#breakfast-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="breakfast-tab-pane"
                      aria-selected="true"
                    >
                      Breakfast
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="lunch-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#lunch-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="lunch-tab-pane"
                      aria-selected="false"
                    >
                      Lunch
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="brunch-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#brunch-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="brunch-tab-pane"
                      aria-selected="false"
                    >
                      Brunch
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="dinner-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#dinner-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="dinner-tab-pane"
                      aria-selected="false"
                    >
                      Dinner
                    </button>
                  </li>
                </ul>
                <div className="tab-content accordion mt-4" id="myTabContent">
                  <div
                    className="tab-pane fade show active accordion-item"
                    id="breakfast-tab-pane"
                    role="tabpanel"
                    aria-labelledby="breakfast-tab"
                    tabIndex={0}
                  >
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingStarCourse">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#StarCourse"
                            aria-expanded="true"
                            aria-controls="StarCourse"
                          >
                            Star Course
                          </button>
                        </h2>
                        <div
                          id="StarCourse"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingStarCourse"
                          data-bs-parent="#accordionExample"
                        >

                        <div className="accordion-body food-dtls-box pb-0">
                        {hotelFood &&
                          hotelFood
                            .filter(food => food.menuDetails.some(menu => menu.name === "Breakfast"))
                            .map(food => (
                              <div key={food._id} className="row align-items-center py-2 py-lg-4 border-btm">
                                <div className="col-md-3">
                                  <figure>
                                    <NavLink onClick={()=>showDetails(food.slug, food._id)}>
                                      <img
                                        src={food?.featured_image}
                                        alt="Popular"
                                        className="img-fluid"
                                      />
                                    </NavLink>
                                  </figure>
                                </div>
                                <div className="col-md-9">
                                  <div className="mt-3 mt-md-0">
                                    <h4>
                                      <b>{food.title}</b>
                                    </h4>
                                    <p className="theme-col-2 mb-1">
                                      {isHTML(food?.description) ? parse(food?.description) : food?.description}
                                    </p>
                                    <h4 className="mb-0">
                                      <b>${food?.price}</b>
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>

                      </div>

                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingLiftOffs">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#LiftOffs"
                            aria-expanded="false"
                            aria-controls="LiftOffs"
                          >
                            Lift-Offs
                          </button>
                        </h2>
                        <div
                          id="LiftOffs"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingLiftOffs"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingSuperNovaSweets"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SuperNovaSweets"
                            aria-expanded="false"
                            aria-controls="SuperNovaSweets"
                          >
                            Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="SuperNovaSweets"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSuperNovaSweets"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingKidsSpace">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSpace"
                            aria-expanded="false"
                            aria-controls="KidsSpace"
                          >
                            Kids' Space 220 Pricing
                          </button>
                        </h2>
                        <div
                          id="KidsSpace"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSpace"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsSuperNova"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSuperNova"
                            aria-expanded="false"
                            aria-controls="KidsSuperNova"
                          >
                            Kids' Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="KidsSuperNova"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSuperNova"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsCelestialBeverages"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsCelestialBeverages"
                            aria-expanded="false"
                            aria-controls="KidsCelestialBeverages"
                          >
                            Kids' Celestial Beverages
                          </button>
                        </h2>
                        <div
                          id="KidsCelestialBeverages"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsCelestialBeverages"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade accordion-item"
                    id="lunch-tab-pane"
                    role="tabpanel"
                    aria-labelledby="lunch-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingStarCourse">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#StarCourse"
                            aria-expanded="true"
                            aria-controls="StarCourse"
                          >
                            Star Course
                          </button>
                        </h2>
                        <div
                          id="StarCourse"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingStarCourse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body food-dtls-box pb-0">
                          {hotelFood &&
                            hotelFood
                              .filter(food => food.menuDetails.some(menu => menu.name === "Lunch"))
                              .map(food => (
                                <div key={food._id} className="row align-items-center py-2 py-lg-4 border-btm">
                                  <div className="col-md-3">
                                    <figure>
                                      <NavLink onClick={()=>showDetails(food.slug, food._id)}>
                                        <img
                                          src={food?.featured_image}
                                          alt="Popular"
                                          className="img-fluid"
                                        />
                                      </NavLink>
                                    </figure>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="mt-3 mt-md-0">
                                      <h4>
                                        <b>{food.title}</b>
                                      </h4>
                                      <p className="theme-col-2 mb-1">
                                        {isHTML(food?.description) ? parse(food?.description) : food?.description}
                                      </p>
                                      <h4 className="mb-0">
                                        <b>${food?.price}</b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingLiftOffs">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#LiftOffs"
                            aria-expanded="false"
                            aria-controls="LiftOffs"
                          >
                            Lift-Offs
                          </button>
                        </h2>
                        <div
                          id="LiftOffs"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingLiftOffs"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingSuperNovaSweets"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SuperNovaSweets"
                            aria-expanded="false"
                            aria-controls="SuperNovaSweets"
                          >
                            Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="SuperNovaSweets"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSuperNovaSweets"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingKidsSpace">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSpace"
                            aria-expanded="false"
                            aria-controls="KidsSpace"
                          >
                            Kids' Space 220 Pricing
                          </button>
                        </h2>
                        <div
                          id="KidsSpace"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSpace"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsSuperNova"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSuperNova"
                            aria-expanded="false"
                            aria-controls="KidsSuperNova"
                          >
                            Kids' Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="KidsSuperNova"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSuperNova"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsCelestialBeverages"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsCelestialBeverages"
                            aria-expanded="false"
                            aria-controls="KidsCelestialBeverages"
                          >
                            Kids' Celestial Beverages
                          </button>
                        </h2>
                        <div
                          id="KidsCelestialBeverages"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsCelestialBeverages"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade accordion-item"
                    id="brunch-tab-pane"
                    role="tabpanel"
                    aria-labelledby="brunch-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingStarCourse">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#StarCourse"
                            aria-expanded="true"
                            aria-controls="StarCourse"
                          >
                            Star Course
                          </button>
                        </h2>
                        <div
                          id="StarCourse"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingStarCourse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body food-dtls-box pb-0">
                          {hotelFood &&
                            hotelFood
                              .filter(food => food.menuDetails.some(menu => menu.name === "Brunch"))
                              .map(food => (
                                <div key={food._id} className="row align-items-center py-2 py-lg-4 border-btm">
                                  <div className="col-md-3">
                                    <figure>
                                      <NavLink onClick={()=>showDetails(food.slug, food._id)}>
                                        <img
                                          src={food?.featured_image}
                                          alt="Popular"
                                          className="img-fluid"
                                        />
                                      </NavLink>
                                    </figure>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="mt-3 mt-md-0">
                                      <h4>
                                        <b>{food.title}</b>
                                      </h4>
                                      <p className="theme-col-2 mb-1">
                                        {isHTML(food?.description) ? parse(food?.description) : food?.description}
                                      </p>
                                      <h4 className="mb-0">
                                        <b>${food?.price}</b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingLiftOffs">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#LiftOffs"
                            aria-expanded="false"
                            aria-controls="LiftOffs"
                          >
                            Lift-Offs
                          </button>
                        </h2>
                        <div
                          id="LiftOffs"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingLiftOffs"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingSuperNovaSweets"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SuperNovaSweets"
                            aria-expanded="false"
                            aria-controls="SuperNovaSweets"
                          >
                            Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="SuperNovaSweets"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSuperNovaSweets"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingKidsSpace">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSpace"
                            aria-expanded="false"
                            aria-controls="KidsSpace"
                          >
                            Kids' Space 220 Pricing
                          </button>
                        </h2>
                        <div
                          id="KidsSpace"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSpace"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsSuperNova"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSuperNova"
                            aria-expanded="false"
                            aria-controls="KidsSuperNova"
                          >
                            Kids' Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="KidsSuperNova"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSuperNova"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsCelestialBeverages"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsCelestialBeverages"
                            aria-expanded="false"
                            aria-controls="KidsCelestialBeverages"
                          >
                            Kids' Celestial Beverages
                          </button>
                        </h2>
                        <div
                          id="KidsCelestialBeverages"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsCelestialBeverages"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade accordion-item"
                    id="dinner-tab-pane"
                    role="tabpanel"
                    aria-labelledby="dinner-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingStarCourse">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#StarCourse"
                            aria-expanded="true"
                            aria-controls="StarCourse"
                          >
                            Star Course
                          </button>
                        </h2>
                        <div
                          id="StarCourse"
                          className="accordion-collapse collapse show"
                          aria-labelledby="headingStarCourse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body food-dtls-box pb-0">
                          {hotelFood &&
                            hotelFood
                              .filter(food => food.menuDetails.some(menu => menu.name === "Dinner"))
                              .map(food => (
                                <div key={food._id} className="row align-items-center py-2 py-lg-4 border-btm">
                                  <div className="col-md-3">
                                    <figure>
                                      <NavLink onClick={()=>showDetails(food.slug, food._id)}>
                                        <img
                                          src={food?.featured_image}
                                          alt="Popular"
                                          className="img-fluid"
                                        />
                                      </NavLink>
                                    </figure>
                                  </div>
                                  <div className="col-md-9">
                                    <div className="mt-3 mt-md-0">
                                      <h4>
                                        <b>{food.title}</b>
                                      </h4>
                                      <p className="theme-col-2 mb-1">
                                        {isHTML(food?.description) ? parse(food?.description) : food?.description}
                                      </p>
                                      <h4 className="mb-0">
                                        <b>${food?.price}</b>
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingLiftOffs">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#LiftOffs"
                            aria-expanded="false"
                            aria-controls="LiftOffs"
                          >
                            Lift-Offs
                          </button>
                        </h2>
                        <div
                          id="LiftOffs"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingLiftOffs"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingSuperNovaSweets"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#SuperNovaSweets"
                            aria-expanded="false"
                            aria-controls="SuperNovaSweets"
                          >
                            Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="SuperNovaSweets"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingSuperNovaSweets"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2 className="accordion-header" id="headingKidsSpace">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSpace"
                            aria-expanded="false"
                            aria-controls="KidsSpace"
                          >
                            Kids' Space 220 Pricing
                          </button>
                        </h2>
                        <div
                          id="KidsSpace"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSpace"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsSuperNova"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsSuperNova"
                            aria-expanded="false"
                            aria-controls="KidsSuperNova"
                          >
                            Kids' Super Nova Sweets
                          </button>
                        </h2>
                        <div
                          id="KidsSuperNova"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsSuperNova"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item mb-2">
                        <h2
                          className="accordion-header"
                          id="headingKidsCelestialBeverages"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#KidsCelestialBeverages"
                            aria-expanded="false"
                            aria-controls="KidsCelestialBeverages"
                          >
                            Kids' Celestial Beverages
                          </button>
                        </h2>
                        <div
                          id="KidsCelestialBeverages"
                          className="accordion-collapse collapse"
                          aria-labelledby="headingKidsCelestialBeverages"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>
                              Lorem Ipsum is simply dummy text of the printing
                              and typesetting industry. Lorem Ipsum has been the
                              industry's standard dummy text ever since the
                              1500s, when an unknown printer took a galley of
                              type and scrambled it to make a type specimen
                              book. It has survived not only five centuries, but
                              also the leap into electronic typesetting,
                              remaining essentially unchanged. It was
                              popularised in the 1960s with the release of
                              Letraset sheets containing Lorem Ipsum passages,
                              and more recently with desktop publishing software
                              like Aldus PageMaker including versions of Lorem
                              Ipsum.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <div className="reviews-sec theme-bg-white p-2 p-lg-4">
                  <div className="sort-cnt">
                    <h4 className="mb-3">Reviews</h4>
                    <div className="pt-3 reviews-box d-flex">
                      <span>
                        <figure>
                          <img
                            src={profileImg}
                            alt="Profile"
                            className="img-fluid"
                          />
                        </figure>
                      </span>
                      <div className="reviews-box-cnt ms-3 pb-3">
                        <p className="mb-1 mt-0">
                          <b>user238574</b>
                        </p>
                        <div className="d-flex align-items-center">
                          <ul>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                          </ul>
                          <small className="ms-2">Aug 16, 2023</small>
                        </div>
                        <span>
                          Space 220 was an incredible journey to the stars! The
                          ambiance was amazing and the food was fantastic. A
                          highlight of our trip to EPCOT this year!
                        </span>
                      </div>
                    </div>
                    <div className="pt-3 reviews-box d-flex">
                      <span>
                        <figure>
                          <img
                            src={profileImg}
                            alt="Profile"
                            className="img-fluid"
                          />
                        </figure>
                      </span>
                      <div className="reviews-box-cnt ms-3 pb-3">
                        <p className="mb-1 mt-0">
                          <b>user238574</b>
                        </p>
                        <div className="d-flex align-items-center">
                          <ul>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                          </ul>
                          <small className="ms-2">Aug 16, 2023</small>
                        </div>
                        <span>
                          Space 220 was an incredible journey to the stars! The
                          ambiance was amazing and the food was fantastic. A
                          highlight of our trip to EPCOT this year!
                        </span>
                      </div>
                    </div>
                    <div className="pt-3 reviews-box d-flex">
                      <span>
                        <figure>
                          <img
                            src={profileImg}
                            alt="Profile"
                            className="img-fluid"
                          />
                        </figure>
                      </span>
                      <div className="reviews-box-cnt ms-3 pb-3">
                        <p className="mb-1 mt-0">
                          <b>user238574</b>
                        </p>
                        <div className="d-flex align-items-center">
                          <ul>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                            <li>
                              <i className="fa-solid fa-star" />
                            </li>
                          </ul>
                          <small className="ms-2">Aug 16, 2023</small>
                        </div>
                        <span>
                          Space 220 was an incredible journey to the stars! The
                          ambiance was amazing and the food was fantastic. A
                          highlight of our trip to EPCOT this year!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      {/* end Filter section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default ListingDetails;
