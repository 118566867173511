import React from 'react';
import { NavLink } from 'react-router-dom';


import '../css/all.css';
import '../css/style.css';
import '../css/responsive.css';
import '../css/bootstrap.min.css';

function FOOTER() {
  return (
    <footer className="footer_section layout_padding pb-4" >
    <div className="container">
      <div className="row align-items-center pb-3 ">
        <div className="col-12 ftr-nav">
          <ul className="nav justify-content-center mb-3 mb-lg-5">
            <li className="nav-item">
              <NavLink to='/about-us'
                className="nav-link theme-hover-col"
              >
                ABOUT
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/blog' className="nav-link theme-hover-col">
                BLOG
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link theme-hover-col">
                PRESS
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/contact-us' className="nav-link theme-hover-col">
                CONTACT
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-12">
          <div className="social_box text-center">
            <ul>
              <li>
                <NavLink>
                  <i className="fa-brands fa-facebook theme-hover-col" />
                </NavLink>
              </li>
              <li>
                <NavLink>
                  <i className="fa-brands fa-instagram theme-hover-col" />
                </NavLink>
              </li>
              <li>
                <NavLink>
                  <i className="fa-brands fa-twitter theme-hover-col" />
                </NavLink>
              </li>
              <li>
                <NavLink>
                  <i className="fa-brands fa-pinterest-p theme-hover-col" />
                </NavLink>
              </li>
              <li>
                <NavLink>
                  <i className="fa-brands fa-tiktok theme-hover-col" />
                </NavLink>
              </li>
              <li>
                <NavLink>
                  <i className="fa-brands fa-youtube theme-hover-col" />
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row align-items-center mt-0 mt-lg-5">
        <div className=" legal-nav" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px"
        }}>
          <ul className="nav justify-content-center justify-content-lg-end">
            <li className="nav-item">
              <NavLink to='/termsofuse' className="nav-link theme-hover-col">
                Terms of Use
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/privacy' className="nav-link">
                Privacy Policy
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/sitemap' className="nav-link">
                Sitemap
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="m-3">
          <div className="ftr-copy-info text-center text-md-center text-lg-start">
            <p style={{fontSize: "16px", textAlign: "center"}}>
            Copyright ⓒ 2024{" "} | All Rights Reserved By Main Street Foodie | Managed by <span><NavLink target="_blank"  to='http://asteeri.com' className="nav-link theme-hover-col"> Asteeri Infotech</NavLink></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default FOOTER