import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import parse from 'html-react-parser';

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import { IoAddCircleSharp } from "react-icons/io5";
// import { IoCloseCircleSharp } from "react-icons/io5";
// import logo from "../../images/logo-inner.png";
// import food1 from "../../images/food-dtl.png";
// import popular1 from "../../images/popular-1.png";
// import popular2 from "../../images/popular-2.png";
// import popular3 from "../../images/popular-3.png";
import profile from "../../images/profile.png";
import placeholderIMG from '../../images/placeholder.svg';

import photo1 from "../../images/photo-1.png";
import photo2 from "../../images/photo-2.png";
import photo3 from "../../images/photo-3.png";
import photo4 from "../../images/photo-4.png";
import photo5 from "../../images/photo-5.png";
import photo6 from "../../images/photo-6.png";
import photo7 from "../../images/photo-7.png";
import photo8 from "../../images/photo-8.png";
import Loader from "../../component/Loader/Loader";
import FOOTER from "../../component/FOOTER";
import { MyContext } from "../../MyContext";
import FavPopup from "./FavPopup";
import AccordionWithPhotos from "../../component/AccordionWithPhotos";
import HEADER from "../../component/HEADER";

function FoodDetails() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { userProfileData, setUserProfileData } = useContext(MyContext)
  const { slug } = useParams();
  const location = useLocation();
  const [id, setId] = useState(location?.state?.id || localStorage.getItem("foodDetailsId"));
  const [FavList , setFavList] = useState([]);
  const [popularHotel, setPopularHotel] = useState([]);

  const photos = [
    {url : photo1}, 
    {url : photo2}, 
    {url : photo3}, 
    {url : photo4}, 
    {url : photo5}, 
    {url : photo6}, 
    {url : photo7}, 
    {url : photo8}, 
  ];

  const [foodData, setFoodData] = useState([]);
  const [isLoading , setIsLoading] = useState(true);
  const [addFavList , setAddFavList] = useState(false);

  useEffect(()=>{
    if (location.state?.id) { //optional
      localStorage.removeItem("foodDetailsId");
    }
    const fetchFoodData = async()=>{
      const response = await fetch(`${backendUrl}/api/food/${id}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      setFoodData(resData.data);
      // console.log(resData);
      localStorage.setItem("foodDetailsId", id);
      setIsLoading(false)
    }

    fetchFoodData();
  },[id])

  useEffect(()=>{
    // console.log(userProfileData?._id)
    const fetchFavList = async()=>{
      const response = await fetch(`${backendUrl}/api/favFood/all/${userProfileData?._id}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      setFavList(resData.data);
      console.log(resData);
    }
    fetchFavList();
  },[addFavList])

  // fetch popular Hotel 
  useEffect(() => {
    const fetchPopularHotel = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/hotel/popular`, {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const resData = await response.json();
        setPopularHotel(resData.data);
        console.log(resData.data);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };
  
    fetchPopularHotel();
  }, []);

  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }

  const showPopUp = ()=>{
    if(userProfileData?._id){
      setAddFavList(prev => !prev);
    }else{
      alert("You have to Login to use this feature!")
    }
  }

  const showDetails = (item,slug, id)=>{
    if(item === "food"){
      navigate(`/food-details/${slug}`,{
        state: { 
          id: id
        }
      })
    }else{
      navigate(`/listing-details/${slug}`,{
        state: { 
          id: id
        }
      })
    }
  }

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <HEADER />
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
          <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/food/all' className='navlink'>Item</NavLink>
              <span className="theme-col-2"> / </span>
              {/* <NavLink to='/listing' className="navlink theme-col-2">
                Hotel
              </NavLink>
              <span className="theme-col-2">/</span>
              <NavLink to='/listing-details' className='navlink'>
                HotelDetails123
              </NavLink> 
              <span className="theme-col-2">/</span> */}
              <NavLink className="navlink theme-color">
                {foodData?.title}
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Filter section */}
      {isLoading ? <Loader/> :
      <section className="filter-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row food-details">
                <div className="col-12">
                  <figure>
                    <img
                      src={foodData?.featured_image || placeholderIMG}
                      alt="Popular"
                      className="img-fluid"
                    />
                  </figure>
                  <div className="row food-cnt mt-0 mt-lg-3 align-items-center">
                    <div className="col-lg-6">
                      <div className="h-100">
                        <h3 className="mt-3">{foodData?.title}</h3>
                        <p className="theme-color">${foodData?.price}</p>

                        <div onClick={showPopUp} className="label-btn py-2 fav-btn">
                          <IoAddCircleSharp fontSize={25}/>
                          <span> Add to Favorite List</span>
                        </div>

                        <div style={{display: "flex", gap: "1rem"}}>
                          {FavList?.filter(item => item.food_ids.includes(id))
                          .map(item => 
                          <div key={item._id} className="label-btn" style={{backgroundColor: "lightgreen", width: "auto", padding: "0.2 0.4rem"}}>
                            <span>{item.title}</span>
                            {/* <IoCloseCircleSharp fontSize={25} color="white"/> */}
                          </div>
                          )}
                        </div>

                      </div>

                      {/* show favorites list to add food */}
                      {addFavList &&
                        <FavPopup closefn={setAddFavList} FavList={FavList} ID={userProfileData?._id} id={id} setIsLoading={setIsLoading}/> 
                      }

                    </div>
                    <div className="col-lg-6">
                      <div className="food-btn d-flex flex-wrap justify-content-start justify-content-lg-end align-items-end">
                      {foodData && foodData.categories && (
                        foodData.categories.map((category, index) => (
                          <NavLink key={index} className='navlink btn-bg1'>
                            {category}
                          </NavLink>
                        ))
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="theme-bg-white food-details p-2 p-lg-4 mt-4">
                  <div className="row food-cnt">
                    {/* <div className="col-md-3">
                      <div>
                        <h3>Cuisine</h3>
                          {foodData?.cuisineDetails?.map((item,index) =>(
                            <span key={item._id} className="theme-col-2 mb-3" style={{fontSize: "20px"}}>{item.name} {index < foodData?.cuisineDetails?.length - 1 ? ', ' : ''}</span>
                          ))}
                      </div>
                    </div> */}

                    {foodData?.menuDetails?.size !==0 && 
                    <div className="col-md-2 mb-3">
                        <h3>Menus</h3>
                        
                          {foodData?.menuDetails?.map(item =>(
                            <span key={item._id} className="theme-col-2" style={{fontSize: "20px"}}>{item.name} {" "}</span>
                          ))}
                    </div>
                    }

                    {foodData?.tagDetails?.size !==0 &&
                    <div className="col-md-2 mb-3">
                        <h3>Tags</h3>
                        
                          {foodData?.tagDetails?.map(item =>(
                            <span key={item._id} className="theme-col-2" style={{fontSize: "20px"}}>{item.name} {" "}</span>
                          ))}
                    </div>
                    }

                    {/* <div className="col-md-2">
                      <div>
                        <h3>Course</h3>
                        <p className="theme-col-2 mb-3">Entree</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div>
                        <h3>Served</h3>
                        <p className="theme-col-2 mb-3">Lunch</p>
                      </div>
                    </div> */}
                  </div>
                  <div className="row food-cnt">
                    <div className="col-12">
                      <div>
                        <h3>Served with</h3>
                        <p className="theme-col-2 mb-3">
                        { foodData?.served_with }
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row food-cnt">
                    <div className="col-12">
                      <div>
                        <h3>Ingredients</h3>
                        <p className="theme-col-2 mb-3">
                        { foodData?.ingredients }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-3">
                <ul
                  className="nav nav-tabs d-none d-lg-flex"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="Description-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Description-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Description-tab-pane"
                      aria-selected="false"
                    >
                      Description
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="Review-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Review-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="Review-tab-pane"
                      aria-selected="false"
                    >
                      Editor Review
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="History-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#History-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="History-tab-pane"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li>
                </ul>
                <div className="tab-content accordion mt-4" id="myTabContent">
                  <div
                    className="tab-pane fade show active accordion-item"
                    id="Description-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Description-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                      {foodData?.description ?
                        <p className="theme-col-2 mb-1">
                          {isHTML(foodData?.description) 
                            ? parse(foodData?.description)
                            : foodData?.description }
                        </p>
                        : <p className="theme-col-2 mb-1">Demo Description ...</p>
                      }
                    </div>
                  </div>
                  <div
                    className="tab-pane fade accordion-item"
                    id="Review-tab-pane"
                    role="tabpanel"
                    aria-labelledby="Review-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                      <p>
                      {foodData?.editor_review ? 
                        <p>{isHTML(foodData?.editor_review) 
                            ? parse(foodData?.editor_review)
                            : foodData?.editor_review }
                        </p> : "No Review Present!"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade accordion-item"
                    id="History-tab-pane"
                    role="tabpanel"
                    aria-labelledby="History-tab"
                    tabIndex={0}
                  >
                    <div className="accordion-body">
                      <p>
                        {foodData?.history ? 
                        <p>{isHTML(foodData?.history) 
                          ? parse(foodData?.history)
                          : foodData?.history}
                        </p> : "No History Present!"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      {/* end Filter section */}
      {/* Start Popular Restaurants section */}
      <section className="popular-sec theme-bg layout_padding pt-0">
        <div className="popular-sec-top theme-bg-2 layout_padding pb-0">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="heading_container_3 text-center">
                  <h1 className="text-white d-inline-block">
                    Popular Restaurants
                  </h1>
                  <h2 className="text-white">Discover the best food</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{marginTop: "-15rem"}}>
          <div className="row popular-cnt-area">
            <div className="container">
              <div className="row popular-cnt-area">
                <div className="col-12">
                  <div className="popular-cnt theme-bg " style={{padding: "3rem"}}>
                    <div className="row g-3">
                      {popularHotel && (
                        popularHotel.map((hotel)=>(
                          <div key={hotel._id} className="col-md-4"
                            onClick={()=>showDetails("hotel",hotel.slug, hotel._id)}
                          >
                            <NavLink className='navlink'>
                            <div className="theme-bg-white p-3 text-center h-100">
                              <figure>
                                <img
                                  src={hotel?.featured_image || placeholderIMG}
                                  alt="Popular"
                                  className="img-fluid"
                                />
                              </figure>
                              <h3 className="theme-color mt-3">
                                {hotel.hotel_name}
                              </h3>
                              <p className="theme-col-2">
                              {hotel.address}
                              </p>
                            </div>
                          </NavLink>
                        </div>
                        ))
                      )}
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3 mt-md-5">
              <div className="reviews-sec theme-bg-white p-2 p-lg-4">
                <div className="sort-cnt">
                  <h4 className="mb-3">Ratings &amp; Reviews</h4>
                </div>
                <div className="row progressbar">
                  <div className="col-12 col-md-10 col-lg-7">
                    <div className="row g-0 d-flex align-items-center mt-3">
                      <div className="col-5">
                        <p className="mb-0">Food Rating</p>
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <span className="active">83</span>
                          <div className="progress">
                            <div
                              className="progress-bar active"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 d-flex align-items-center mt-3">
                      <div className="col-5">
                        <p className="mb-0">Taste &amp; Flavor</p>
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <span>9.0</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 d-flex align-items-center mt-3">
                      <div className="col-5">
                        <p className="mb-0">Quality &amp; Skillfulness</p>
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <span>6.5</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 d-flex align-items-center mt-3">
                      <div className="col-5">
                        <p className="mb-0">Quality &amp; Skillfulness</p>
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <span>8.0</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 d-flex align-items-center mt-3">
                      <div className="col-5">
                        <p className="mb-0">Price &amp; Value</p>
                      </div>
                      <div className="col-7">
                        <div className="d-flex">
                          <span>8.5</span>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "80%" }}
                              aria-valuenow={25}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <button className="cmn_btn btn btn-lg btn-bg w-100">
                        Leave a Review
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="filter-sec mt-3 mt-md-5">
              <div className="accordion" id="accordionExample">
                <div className="accordion-item mb-2">
                  <h2 className="accordion-header" id="headingMostRecent">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#MostRecent"
                      aria-expanded="true"
                      aria-controls="MostRecent"
                    >
                      Most Recent
                    </button>
                  </h2>
                  <div
                    id="MostRecent"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingMostRecent"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body food-dtls-box pb-0">
                      <div className="reviews-sec">
                        <div className="sort-cnt">
                          <div className="pt-3 reviews-box d-flex">
                            <span>
                              <figure>
                                <img
                                  src={profile}
                                  alt="Profile"
                                  className="img-fluid"
                                />
                              </figure>
                            </span>
                            <div className="reviews-box-cnt ms-3 pb-3">
                              <p className="mb-1 mt-0">
                                <b>user238574</b>
                              </p>
                              <div className="d-flex align-items-center">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                </ul>
                                <small className="ms-2">Aug 16, 2023</small>
                              </div>
                              <span>
                                Space 220 was an incredible journey to the
                                stars! The ambiance was amazing and the food was
                                fantastic. A highlight of our trip to EPCOT this
                                year!
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 reviews-box d-flex">
                            <span>
                              <figure>
                                <img
                                  src={profile}
                                  alt="Profile"
                                  className="img-fluid"
                                />
                              </figure>
                            </span>
                            <div className="reviews-box-cnt ms-3 pb-3">
                              <p className="mb-1 mt-0">
                                <b>user238574</b>
                              </p>
                              <div className="d-flex align-items-center">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                </ul>
                                <small className="ms-2">Aug 16, 2023</small>
                              </div>
                              <span>
                                Space 220 was an incredible journey to the
                                stars! The ambiance was amazing and the food was
                                fantastic. A highlight of our trip to EPCOT this
                                year!
                              </span>
                            </div>
                          </div>
                          <div className="pt-3 reviews-box d-flex">
                            <span>
                              <figure>
                                <img
                                  src={profile}
                                  alt="Profile"
                                  className="img-fluid"
                                />
                              </figure>
                            </span>
                            <div className="reviews-box-cnt ms-3 pb-3">
                              <p className="mb-1 mt-0">
                                <b>user238574</b>
                              </p>
                              <div className="d-flex align-items-center">
                                <ul>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                  <li>
                                    <i className="fa-solid fa-star" />
                                  </li>
                                </ul>
                                <small className="ms-2">Aug 16, 2023</small>
                              </div>
                              <span>
                                Space 220 was an incredible journey to the
                                stars! The ambiance was amazing and the food was
                                fantastic. A highlight of our trip to EPCOT this
                                year!
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item mb-2">
                  <h2 className="accordion-header" id="headingNutritionFacts">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#NutritionFacts"
                      aria-expanded="false"
                      aria-controls="NutritionFacts"
                    >
                      Nutrition Facts
                    </button>
                  </h2>
                  <div
                    id="NutritionFacts"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingNutritionFacts"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software like
                        Aldus PageMaker including versions of Lorem Ipsum.
                      </p>
                    </div>
                  </div>
                </div>
                <AccordionWithPhotos photos={photos} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End Popular Restaurants section  */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default FoodDetails;
