import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';
import parse from "html-react-parser";

import '../../css/all.css';
import '../../css/style.css';
import '../../css/responsive.css';
import '../../css/bootstrap.min.css';

import logo from '../../images/logo-inner.png';
import about1 from '../../images/about1.png';
import about2 from '../../images/about2.png';
import FOOTER from "../../component/FOOTER";
import HEADER from "../../component/HEADER";

function About() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [aboutData, setAboutData] = useState([]);
  //fetch About data
  useEffect(() => {
    const fetchAllBlogs = async () => {
      const response = await fetch(`${backendUrl}/api/cms/all`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setAboutData(resData.data.filter((item)=>item._id === "674892883f98fbb20d657f10"));
      console.log(resData.data);
    };

    fetchAllBlogs()
  }, []);

  return (
    <div style={{fontFamily:"Arimo"}}>
      <HEADER/>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/'className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to='/about-us' className="theme-color navlink">
                About Us
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="about-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="heading_container">
                <h1 className="theme-color d-inline-block">
                  Lorem ipsum dolor
                </h1>
                <h2 className="theme-col-2">{aboutData ? aboutData[0]?.name : "About Us"}</h2>
                <p className="theme-col-2 mt-3">
                  {aboutData[0] ? parse(aboutData[0]?.content[0]) : 
                  "Thousands of MSF users have rated and reviewed the food at Disney's Food &amp; Wine Festival. Have a look at what they choose as the best of the fest"}
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-md-6 order-2 order-md-1">
              <div className="abt-cnt">
                <p className="theme-col-2 mt-3">
                {aboutData[0] && parse(aboutData[0]?.content[1])}
                </p>
                {/* <p className="theme-col-2 mt-3">
                {aboutData[0] && parse(aboutData[0]?.content[2])}
                </p> */}
              </div>
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div className="abt-img">
                <figure>
                  <img
                    src={aboutData ? aboutData[0]?.image[0] : about1}
                    alt="About"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-3 mt-md-5">
            <div className="col-md-6">
              <div className="abt-img">
                <figure>
                  <img
                    src={aboutData ? aboutData[0]?.image[1] : about2}
                    alt="About"
                    className="img-fluid"
                  />
                </figure>
              </div>
            </div>
            <div className="col-md-6">
              <div className="abt-cnt ps-0 ps-md-3">
                <p className="theme-col-2 mt-3">
                {aboutData[0] && parse(aboutData[0]?.content[2])}
                </p>
                {/* <p className="theme-col-2 mt-3">
                {aboutData[0] && parse(aboutData[0]?.content[4])}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default About;
