import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import { RouterProvider, Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import Layout from './Layout'

import ErrorPage from './pages/ErrorPage/ErrorPage.jsx'
import Login from './pages/login/Login.jsx';
import Signup from './pages/signup/Signup.jsx';
import Privacy from './pages/privacy/Privacy.jsx';
import Profile from './pages/profile/Profile.jsx';
import Listing from './pages/listing/Listing.jsx';
import ListingDetails from './pages/listing/ListingDetails.jsx';
import About from './pages/about/About.jsx';
import Blog from './pages/blog/Blog.jsx';
import BlogDetails from './pages/blog/BlogDetails.jsx';
import EditProfile from './pages/profile/EditProfile.jsx';
import Contact from './pages/contact/Contact.jsx';
import FoodDetails from './pages/food/FoodDetails.jsx';
import Food from './pages/food/Food.jsx';
import FavFood from './pages/profile/FavFood.jsx';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Sitemap from './pages/privacy/Sitemap.jsx';
import TermsOfUse from './pages/privacy/TermsOfUse.jsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<Layout/>}>
      <Route path='/' element={<App/>}/>
      <Route path='/login' element={<Login/>}/>
      <Route path='/signup' element={<Signup/>}/>
      <Route path='/profile/:ID' element={<Profile/>} />
      <Route path='/edit-profile/:ID' element={<EditProfile/>} />
      <Route path='/listing/:Hotel' element={<Listing/>} />
      <Route path='/listing-details/:slug' element={<ListingDetails/>} />
      <Route path='/about-us' element={<About/>} />
      <Route path='/contact-us' element={<Contact/>} />
      <Route path='/blog' element={<Blog/>} />
      <Route path='/blog-details/:slug' element={<BlogDetails/>} />
      <Route path='/food/:FOOD' element={<Food/>} />
      <Route path='/food-details/:slug' element={<FoodDetails/>} />
      <Route path='/privacy' element={<Privacy/>}/>
      <Route path='/sitemap' element={<Sitemap/>}/>
      <Route path='/termsofuse' element={<TermsOfUse/>}/>

      <Route path='/favorite-food/:ID' element={<FavFood/>}/>
      <Route path='*' element={<ErrorPage/>}/>
    </Route>
 )
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <RouterProvider router={router} />
  // </React.StrictMode>
);
