import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import '../../css/all.css';
import '../../css/style.css';
import '../../css/responsive.css';
import '../../css/bootstrap.min.css';

import googleImg from "../../images/google.png";
import bgImg from "../../images/login-bg.png"
import logo from '../../images/logo.png';

function MainSignup() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [err, setErr] = useState('');
  
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!Email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      newErrors.email = "Invalid email format";
    }

    if (!Password.trim()) {
      newErrors.password = "Password is required";
    }else if(Password.length < 5){
      newErrors.password = "password must be at least 5 characters";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    const data = {
      Email: Email,
      Password: Password,
    };

    try {
      const response = await fetch(`${backendUrl}/api/user/signup`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const responesData = await response.json();

      setErr(responesData.message)

      if (responesData.message === "Signup successfull") {
        setErr("Now Varify Email to access your account");
      }

    } catch (error) {
      setErrors(error.message);
      setErr(error.message)
    } finally{
      setErrors(newErrors);
    }
  };
  
  //Function to handle Google Login
  const handleGoogleSubmit = async(authResult) => {
    console.log("authResult :",authResult)
    try {
      if(authResult['code']){
        const fetchGoogleData = await fetch(`${backendUrl}/api/user/googleLogin`, {
          method: 'POST',
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: authResult['code'] })
        });

        const result = await fetchGoogleData.json();
        if(result.statusCode === 200){
          navigate(`/profile/${result.data.user._id}`)
        }
      }
      
    } catch (error) {
      console.log('Error from Google Login: ',error);
    }
    
  }

  //Function call google service
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSubmit,
    onError: handleGoogleSubmit,
    flow: "auth-code"
  });

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* <ToastContainer /> */}
      <section className="login theme-login d-flex align-items-center">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="row h-100 justify-content-center align-items-center">
              <NavLink to='/' style={{marginLeft: "4.2rem"}}>
                <img src={logo} alt="logo" className="d-none d-lg-inline-block" width={200} />
                <img src={logo} alt="logo" className="d-lg-none mob-logo" style={{marginTop: "-4.5rem"}} width={120}/>
              </NavLink>
                <div className="col-10 col-md-7">
                  <div className="row">
                    <div className="col-12">
                      <div className="heading_container_4">
                        <h2 className="theme-col-2">Create a Free Account</h2>
                        <p className="theme-col-2">
                          Sign up with your email address &amp; Password
                        </p>
                      </div>
                    </div>
                    <div className="login-form mt-3">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="emailinput" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            value={Email}
                            onChange={(e)=>setEmail(e.target.value)}
                            className="form-control"
                            id="email"
                            placeholder="Email address"
                          />
                          {errors.email && (
                            <div className="theme-color">! {errors.email}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="passwordinput" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            value={Password}
                            onChange={(e)=>setPassword(e.target.value)}
                            className="form-control"
                            id="passwordinput"
                            placeholder="Password"
                          />
                          {errors.password && (
                            <div className="theme-color">! {errors.password}</div>
                          )}
                        </div>
                        {err && (
                            <div className={err.includes('access') ? "theme-col-6" : "theme-color"} style={{textAlign: "center", fontSize: "large"}}>! {err}</div>
                        )}
                        <div className="mb-2">
                          <button type="submit" className="cmn_btn btn btn-lg btn-bg w-100">
                            Register
                          </button>
                        </div>
                        <div className="mb-3 pt-3 text-center">
                          <p className="login-with text-center">
                          ------- OR -------
                          </p>
                        </div>
                        <div className="mb-3">
                          <button onClick={googleLogin} className="cmn_btn btn btn-lg btn-bg-2 w-100">
                          <img src={googleImg} alt="Google" width={20}/>{" "}<span className="theme-col-white"> Login with Google </span>
                          </button>
                        </div>
                        
                        <div className="text-center">
                          <p className="signup text-center mb-0">
                            Already a member?{" "}
                            <NavLink to='/login'
                              className="theme-color navlink">
                              Sign in
                            </NavLink>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <figure className="mb-0">
                <img src={bgImg} alt="Login" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Signup = ()=>{
  return(
    <GoogleOAuthProvider clientId='630712279158-kf1tavaki9k2uismci5sfgu0f5uctjgb.apps.googleusercontent.com'>
      <MainSignup></MainSignup>
    </GoogleOAuthProvider>
  )
}

export default Signup;