import React , { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

import { useGoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

import '../../css/all.css';
import '../../css/style.css';
import '../../css/responsive.css';
import '../../css/bootstrap.min.css';

import googleImg from "../../images/google.png";
import bgImg from "../../images/login-bg.png";
import logo from '../../images/logo.png';

function MainLogin() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [err, setErr] = useState('');

  const navigate = useNavigate();

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Client-side validation
    const newErrors = {};

    if (!Email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      newErrors.email = "Invalid email format";
    }

    if (!Password.trim()) {
      newErrors.password = "Password is required";
    }

    if (Object.keys(newErrors).length > 0) {
      // Update the errors state and prevent form submission
      setErrors(newErrors);
      return;
    }

    // Prepare data object to send to the backend
    const data = {
      Email: Email,
      Password: Password,
    };

    try {
      // Send data to backend (you need to implement this part)
      const response = await fetch(`${backendUrl}/api/user/login`, {
        method: 'POST',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });


      const responesData = await response.json()
      if(responesData.message !== 'Logged in'){
        setErr(responesData.message);
        // toast(responesData.message);
      }
  
      console.log( responesData )
      const ID = responesData.data.user._id;

      // Handle response
      if (response.ok) {
        // Authentication successful, you can redirect or do something else
        console.log("Login successful");
        navigate(`/profile/${ID}`)

      } else if (response.status === 401) {
        // Incorrect password
        setErrors({ password: responesData.message || "Incorrect password" });
      } else if (response.status === 403) {
        // Account locked, disabled, or other authentication issues

        setErrors({ general: responesData.message || "Login failed" });
      } else if (response.status === 400) {
        setErrors({ general: responesData.message || "User does not exist" });
      } else if (response.status === 422) {
        setErrors({
          general: responesData.message || '"Email" must be a valid email',
        });
      } else {
        // Other unexpected errors
        setErrors({ general: "An unexpected error occurred" });
      }
    } catch (error) {
    
      setErrors(error.message);
    }
  };

  //Function to handle Google Login
  const handleGoogleSubmit = async(authResult) => {
    console.log("authResult :",authResult)
    try {
      if(authResult['code']){
        const fetchGoogleData = await fetch(`${backendUrl}/api/user/googleLogin`, {
          method: 'POST',
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code: authResult['code'] })
        });

        const result = await fetchGoogleData.json();
        if(result.statusCode === 200){
          navigate(`/profile/${result.data.user._id}`)
        }
      }
      
    } catch (error) {
      console.log('Error from Google Login: ',error);
    }
    
  }

  //Function call google service
  const googleLogin = useGoogleLogin({
    onSuccess: handleGoogleSubmit,
    onError: handleGoogleSubmit,
    flow: "auth-code"
  });

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* <ToastContainer /> */}
      <section className="login theme-login d-flex align-items-center">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="col-lg-6">
              <div className="row h-100 justify-content-center align-items-center">
                <NavLink to='/' style={{marginLeft: "4rem"}}>
                  <img src={logo} alt="logo" className="d-none d-lg-inline-block" width={200} />
                  <img src={logo} alt="logo" className="d-lg-none mob-logo" style={{marginTop: "-4rem"}} width={120}/>
                </NavLink>
                <div className="col-10 col-md-7">
                  <div className="row">
                    <div className="col-12">
                      <div className="heading_container_4">
                        <h2 className="theme-col-2">Welcome Back</h2>
                        <p className="theme-col-2">
                          Sign in with your email address and Password
                        </p>
                      </div>
                    </div>
                    <div className="login-form mt-3">
                      <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                          <label htmlFor="emailinput" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Email Address"
                            value={Email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {errors.email && (
                            <div className="theme-color">! {errors.email}</div>
                          )}
                        </div>
                        <div className="mb-3">
                          <label htmlFor="passwordinput" className="form-label">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            id="passwordinput"
                            placeholder="Password"
                            value={Password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          {errors.password && (
                            <div className="theme-color">! {errors.password}</div>
                          )}
                        </div>
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                          <div className="form-check">
                            <input
                              className="form-check-input border"
                              type="checkbox"
                              defaultValue=""
                              id="rememberme"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberme"
                            >
                              Remember me
                            </label>
                          </div>
                          <NavLink className="navlink theme-col-2">
                            Forgot Password?
                          </NavLink>
                        </div>
                        {err && (
                            <div className="theme-color" 
                            style={{textAlign: "center", fontSize: "large"}}
                            >! {err}</div>
                        )}
                        <div className="mb-3">
                          <button type="submit" className="cmn_btn btn btn-lg btn-bg w-100">
                            Submit
                          </button>
                        </div>
                      </form>
                        <div className="mb-3 pt-3 text-center">
                          <p className="login-with text-center">
                          ------- OR -------
                          </p>
                        </div>
                        <div className="mb-3">
                          <button onClick={googleLogin} className="cmn_btn btn btn-lg btn-bg-2 w-100">
                          <img src={googleImg} alt="Google" width={20}/>{" "}<span className="theme-col-white"> Login with Google </span>
                          </button>
                        </div>
                        <div className="text-center">
                          <p className="signup text-center mb-0">
                            Don’t have an account?{" "}
                            <NavLink to='/signup' className="navlink theme-color">
                              Sign up
                            </NavLink>
                          </p>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <figure className="mb-0">
                <img src={bgImg} alt="Login" />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

const Login = ()=>{
  return(
    <GoogleOAuthProvider clientId='630712279158-kf1tavaki9k2uismci5sfgu0f5uctjgb.apps.googleusercontent.com'>
      <MainLogin></MainLogin>
    </GoogleOAuthProvider>
  )
}

export default Login;
