import React, { useState } from 'react'
import {Outlet} from 'react-router-dom'
import Relocation from './component/Relocation'
import { MyContext } from "./MyContext";

function Layout() {
  const [userProfileData, setUserProfileData] = useState({});
  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  // Safely parse the value from localStorage
  const userData = localStorage.getItem("user_data");
  const userID = userData ? JSON.parse(userData) : null;

  useState(()=>{
    const userLogin = async () => {
      const response = await fetch(`${backendUrl}/api/user/profile/${userID}`, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const responseData = await response.json();
      setUserProfileData(responseData.data);
    };
  
    if(userID){
      userLogin();
    }
  },[userID])

  return (
    <>
    <MyContext.Provider value={{ userProfileData, setUserProfileData }}>
      <Relocation/>
      <Outlet/>
    </MyContext.Provider>
    </>
  )
}

export default Layout;