import React from 'react'
import { NavLink } from "react-router-dom";
import Error from './Images/error.svg'

function ErrorPage() {
  return (
    <>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        gap: "3vh",
        background: "purple"
      }}
    >
      <img src={Error} alt="" width={350} />
      <NavLink to="/"
        style={{
          color: "#842e92",
          background: "white",
          cursor: "pointer",
          padding: "1rem 2rem",
          borderRadius: "2px",
          fontSize: "1.5rem",
          textDecoration: 'none',
          fontWeight: "bolder"
        }}
      >Back To Home</NavLink>
    </div>
    </>
  )
}

export default ErrorPage