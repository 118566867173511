import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import { FaRegHeart } from "react-icons/fa";
import { FaHeart } from "react-icons/fa6";
import logo from "../../images/logo-inner.png";
import profile from "../../images/profile.png";
import placeholderIMG from '../../images/placeholder.svg';
import Loader from "../../component/Loader/Loader";
import ScrollToTop from "../../component/ScrollToTop";
import FOOTER from "../../component/FOOTER";
import { MyContext } from "../../MyContext";

function Food() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userProfileData, setUserProfileData } = useContext(MyContext);
  const navigate = useNavigate();
  const { FOOD } = useParams(); //food name comming from home

  const [food, setFood] = useState(FOOD); //final key value to fetch food
  const [foodName, setFoodName] = useState(""); //search keyvalue 
  const [foodData, setFoodData] = useState([]); //actual Data
  const [foodTag, setFoodTag] = useState();//store category
  const [foodMenu , setFoodMenu] = useState([]);
  const [sortPrice, setSortPrice] = useState('')
  const [selectedPriceRange, setSelectedPriceRange] = useState("");
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [selectedTag, setSelectedTag] = useState([]);

  const [isLoading , setIsLoading] = useState(true);
  const [pageNo , setPageNo] = useState(1);

  const handleLogoutByContext = (e)=>{
    e.preventDefault();
    localStorage.removeItem("user_data");
    setUserProfileData([]);
    navigate("/");
  }

  const handleTagChange = (tagID) => {
    setPageNo(1)
    setFoodData([])
    setSelectedTag(prev => 
      prev.includes(tagID) ? prev.filter(t => t !== tagID) : [...prev, tagID]
    );
  };

  const handleMenuChange = (menuID) => {
    setPageNo(1)
    setFoodData([])
    setSelectedMenu(prev => 
      prev.includes(menuID) ? prev.filter(t => t !== menuID) : [...prev, menuID]
    );
  };

  const handlePriceChange = (priceRange) => {
    if(priceRange !== selectedPriceRange){
      setPageNo(1)
      setFoodData([])
      setSelectedPriceRange(priceRange);
    }
  };

  const handleLoadData = ()=>{
    setIsLoading(true)
    setPageNo(prev => prev + 1)
  }

  // useEffect(()=>{
  //   let filteredData = [...foodData]

  //       //Sort by Price
  //   if (sortPrice) {
  //     if(sortPrice === 'all'){
  //       filteredData = filteredData;
  //     }else if (sortPrice === 'low-high') {
  //       filteredData = filteredData.sort((a, b) => a.price - b.price);
  //     } else if (sortPrice === 'high-low') {
  //       filteredData = filteredData.sort((a, b) => b.price - a.price);
  //     }
  //   }
  
  //   setFoodData(filteredData);
  // },[sortPrice])

  //call food tags api
  useEffect(() => {
    const fetchCategory = async () => {
      const response = await fetch(`${backendUrl}/api/tag/all`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setFoodTag(resData.data);
      // console.log(resData.data);
    };

    fetchCategory()
  },[])

  // call food menu api
  useEffect(() => {
    const fetchMenu = async () => {
      const response = await fetch(`${backendUrl}/api/menu/all`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setFoodMenu(resData.data);
      // console.log(resData.data);
    };

    fetchMenu()
  },[])
  
  //fetch food data
  useEffect(() => {
    //call all food api
    const fetchFoodAllData = async () => {
      const response = await fetch(`${backendUrl}/api/food/all`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setFoodData(resData.data.slice(0, 15));
    };

    //call food by search keyword
    const fetchFoodData = async () => {
      const response = await fetch(`${backendUrl}/api/food/search/${food}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setFoodData(resData.data.slice(0, 15));
    };

    if (food === "all") { //all for all food
      fetchFoodAllData();
    } else {
      fetchFoodData();
    }
  }, [food]);

  //set food name in search
  const handleSubmit = (e) => {
    e.preventDefault();
    setPageNo(1)
    setFoodData([])
    setFood(foodName);
  };

  //handle all filters
  useEffect(() => {
    const filter = {
      sortPrice : sortPrice,
      selectedMenu : selectedMenu,
      selectedPriceRange : selectedPriceRange,
      selectedTag : selectedTag
    }

    setIsLoading(true)

    const fetchFilterData = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/food/filter/${pageNo}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(filter),
          }
        );
    
        if (!response.ok) {
          console.error("API request failed:", response.status, response.statusText);
          return;
        }
    
        const resData = await response.json();

        setFoodData((prev) => [...prev, ...resData.data]);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false)
      }
    };
    
    fetchFilterData();
  
  }, [pageNo, sortPrice, selectedMenu, selectedPriceRange, selectedTag]);
  
  // //infinite scroll
  // const handleScroll = ()=>{
  //   const {scrollTop, clientHeight, scrollHeight} = document.documentElement;

  //   if(scrollTop + clientHeight + 10 >= scrollHeight){
  //     setIsLoading(true);
  //     setPageNo(prev => prev + 1)
  //   }
  // }

  // useEffect(()=>{
  //   window.addEventListener("scroll", handleScroll);

  //   return ()=> window.removeEventListener("scroll", handleScroll)
  // },[])
  
  const handleSortData = (e)=>{
    setPageNo(1);
    setFoodData([]);
    setSortPrice(e.target.value)
  }

  const showDetails = (slug, id)=>{
    navigate(`/food-details/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }

  return (
    <div style={{ fontFamily: "Arimo" }}>
      {/* header section strats */}
      <header className="header_section" id="hdr-section">
        <section className="header_area inner-page-header">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <NavLink to="/" className="navbar-brand">
                <img
                  src={logo}
                  alt="Logo"
                  className="d-none d-lg-inline-block"
                  width={274}
                />
                <img
                  src={logo}
                  alt="Logo"
                  className="d-lg-none mob-logo"
                  width={180}
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <form
                  className="hdr-search-area ms-0 ms-lg-5"
                  onSubmit={handleSubmit}
                >
                  <div className="input-group" style={{marginBottom: "3px"}}>
                    <input
                      value={foodName}
                      onChange={(e) => setFoodName(e.target.value)}
                      type="text"
                      className="form-control"
                      placeholder="Search for anything"
                      aria-label="Dollar amount (with dot and two decimal places)"
                    />
                    <span className="input-group-text" onClick={handleSubmit}>
                      <i className="fa-solid fa-magnifying-glass" />
                    </span>
                  </div>
                </form>

                {userProfileData.user_name ? 
                <>
                
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav ms-auto user-pro">
                    <li className="nav-item">
                      <span style={{marginRight: "10px"}}>
                        <img
                          src={userProfileData?.profile_img_url }
                          alt="profile"
                          className="img-fluid"
                        />
                      </span>
                      <div className="dropdown">
                        <button
                          className="btn btn-sm theme-bg-white dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {userProfileData?.user_name ? userProfileData?.user_name : 'User Name' }
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button className="dropdown-item" onClick={()=>navigate(`/profile/${userProfileData?._id}`)}>
                              My Account
                            </button>
                          </li>
                          <li>
                            <button onClick={handleLogoutByContext}  className="dropdown-item">
                              Log Out
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                </>
                : 
                <>
                  
                  <div className="homenavbar collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto log-sign" >
                      <li className="nav-item">
                        <NavLink to='/login'
                          className="nav-link theme-color"
                        >
                          Log in
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to='/signup'
                          className="nav-link theme-color"
                        >
                          Sign up
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
                }

              </div>
            </div>
          </nav>
        </section>
      </header>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to="/" className="navlink">
                Home
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink onClick={() => setFood("all")} className="navlink">
                Item
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink className="navlink theme-color">{food}</NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}

      {/* Start Filter section */}
      <section className="filter-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="filter-bar mb-3">
                <p>Filters</p>
              </div>
              <div
                className="filter-bar accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Resort">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Resort"
                      aria-expanded="false"
                      aria-controls="Resort"
                    >
                      Tag
                    </button>
                  </h2>
                  <div
                    id="Resort"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Resort"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body ">
                      {foodTag?.map((cat, index) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id={index}
                            onChange={() => handleTagChange(cat._id)}
                            checked={selectedTag.includes(cat._id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={index}
                          >
                            {cat.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-PriceRange">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#PriceRange"
                      aria-expanded="false"
                      aria-controls="PriceRange"
                    >
                      Price Range
                    </button>
                  </h2>
                  <div
                    id="PriceRange"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-PriceRange"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked55"
                          onChange={() => handlePriceChange('All')}
                          checked={selectedPriceRange === 'All'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked55"
                        >
                          All
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked00"
                          onChange={() => handlePriceChange('under-50')}
                          checked={selectedPriceRange === 'under-50'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked00"
                        >
                          $0 - $50
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked11"
                          onChange={() => handlePriceChange('50-100')}
                          checked={selectedPriceRange === '50-100'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked11"
                        >
                          $50 - $100
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked22"
                          onChange={() => handlePriceChange('above-100')}
                          checked={selectedPriceRange === 'above-100'}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked22"
                        >
                        $100 & more
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Type">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Type"
                      aria-expanded="false"
                      aria-controls="Type"
                    >
                      Menu
                    </button>
                  </h2>
                  <div
                    id="Type"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Type"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {foodMenu && foodMenu.map( types => (
                        <div className="form-check" key={types._id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id={types.name}
                            onChange={() => handleMenuChange(types._id)}
                            checked={selectedMenu.includes(types._id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={types.name}
                          >
                            {types.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="filter-bar mb-3">
                <div className="row text-end justify-content-end ">
                  <label
                    htmlFor="filterselect"
                    className="col-3 col-md-6 col-lg-8 col-form-label"
                  >
                    Sort by:
                  </label>
                  <div className="col-9 col-md-6 col-lg-4">
                    <select
                      className="form-select"
                      id="filterselect"
                      aria-label="Default select example"
                      onChange={(e)=>handleSortData(e)}
                    >
                      <option value='all'>Recommended</option>
                      <option value='high-low'>Price: high-low ↓</option>
                      <option value='low-high'>Price: low-high ↑</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row g-4 sort-cnt favorites-box">
                {foodData?.length !== 0
                  ? foodData?.map((food) => (
                      <div className="col-md-4 Cpointer" key={food._id} 
                        onClick={()=>showDetails(food.slug, food._id)}>
                          <div className="theme-bg-white p-3 h-100">
                            <figure>
                              <img
                                src={food.featured_image || placeholderIMG}
                                alt={food.title}
                                className="img-fluid"
                              />
                            </figure>
                            <h3 className="mt-3">{food.title}</h3>
                            <div style={{
                              display: "flex",
                              alignItems: "flex-end", 
                              justifyContent: "space-between",
                            }}>
                              <p>${food.price}</p>
                              {/* <p><FaRegHeart /> </p> */}
                            </div>
                            {food?.description ?
                            <p className="theme-col-2 mb-1">
                              {isHTML(food?.description) 
                                ? parse(food?.description.substring(0, 70))
                                : `${food?.description?.substring(0, 70)} ...`}
                            </p>
                            : <p className="theme-col-2 mb-1">Demo Description ...</p>
                            }
                          </div>
                      </div>
                    ))
                    : <p style={{textAlign : "center", marginTop: "3rem"}}>"No Food Found!"</p>}
              </div>

              <div onClick={handleLoadData} className="read-more-btn">
                {isLoading ? "Loading Data ..." : "Load More"}
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* end Filter section */}

      {/* footer section */}
      <FOOTER/>
      <ScrollToTop/>
    </div>
  );
}

export default Food;
