import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import '../../css/all.css';
import '../../css/style.css';
import '../../css/responsive.css';
import '../../css/bootstrap.min.css';

import logo from '../../images/logo-inner.png';
import placeholderIMG from '../../images/placeholder.svg';
import Loader from "../../component/Loader/Loader";
import ScrollToTop from "../../component/ScrollToTop";
import FOOTER from "../../component/FOOTER";
import { MyContext } from "../../MyContext";

function Listing() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const { userProfileData, setUserProfileData } = useContext(MyContext)
  const navigate = useNavigate();
  const { Hotel } = useParams();
  const location = useLocation();
  const [hotel, setHotel] = useState(Hotel);
  const [hotelName, setHotelName] = useState('');
  const [hotelData, setHotelData] = useState([]);
  const [sortRating, setSortRating] = useState('');
  const [isLoading , setIsLoading] = useState(true);
  const [pageNo , setPageNo] = useState(1);

  const [foodMenu , setFoodMenu] = useState([]);
  const [foodType , setFoodType] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState(location.state == null ? [] : [location.state]);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState([]);

  const handleLogoutByContext = ()=>{
    localStorage.removeItem("user_data");
    setUserProfileData([]);
    navigate("/");
  }

  const handleCuisineChange = (type) => {
    setPageNo(1)
    setHotelData([])
    setSelectedCuisine(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleTypeChange = (type) => {
    setPageNo(1)
    setHotelData([])
    setSelectedTypes(prev => 
      prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
    );
  };

  const handleMenuChange = (menuID) => {
    setPageNo(1)
    setHotelData([])
    setSelectedMenu(prev => 
      prev.includes(menuID) ? prev.filter(t => t !== menuID) : [...prev, menuID]
    );
  };

  const handlePriceChange = (priceRange) => {
    setPageNo(1)
    setHotelData([])
    setSelectedPriceRange(prev => 
      prev.includes(priceRange) ? prev.filter(t => t !== priceRange) : [...prev, priceRange]
    );
  };

  const handleLoadData = ()=>{
    setIsLoading(true)
    setPageNo(prev => prev + 1)
  }

  const showDetails = (slug, id)=>{
    navigate(`/listing-details/${slug}`,{
      state: { 
        id: id
      }
    })
  }

  const isHTML = (description) =>{
    const result = /<\/?[a-z][\s\S]*>/i.test(description);
    return result;
  }

  useEffect(()=>{
    const fetchAllHotelData = async()=>{
      const response = await fetch(`${backendUrl}/api/hotel/all`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      setHotelData(resData.data.slice(0, 14));
    }

    const fetchHotelData = async()=>{
      const response = await fetch(`${backendUrl}/api/hotel/search/${hotel}`,{
        method: 'GET',
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      })
      const resData = await response.json();
      setHotelData(resData.data.slice(0, 14));
    }

    if(selectedCuisine.length === 0){
      if(hotel === 'all'){
        fetchAllHotelData();
      }else{
        fetchHotelData();
      }
    }else{
      handleCuisineChange(selectedCuisine[0])
    }
  },[hotel])

  const handleSubmit = (e)=>{
    setPageNo(1)
    setHotelData([])
    e.preventDefault();
    setHotel(hotelName);
  }

  useEffect(()=>{
    const filter = {
      selectedMenu : selectedMenu,
      selectedPriceRange : selectedPriceRange,
      selectedTypes : selectedTypes,
      selectedCuisine : selectedCuisine
    }
  
    
    const fetchFilterData = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/hotel/filter/${pageNo}`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(filter),
          }
        );
    
        if (!response.ok) {
          // Handle non-200 status codes here (e.g., show an error message)
          console.error("API request failed:", response.status, response.statusText);
          return;
        }
    
        const resData = await response.json();
    
        // Assuming resData.data is an array, spread it into the previous data
        setHotelData((prev) => [...prev, ...resData.data]);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle the error (e.g., show an error message to the user)
      } finally {
        setIsLoading(false)
        setSelectedCuisine([])
      }
    };

    fetchFilterData();

  },[pageNo,selectedPriceRange, selectedMenu, selectedTypes])

    // //infinite scroll
    // const handleScroll = ()=>{
    //   const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
  
    //   if(scrollTop + clientHeight + 10 >= scrollHeight){
    //     setIsLoading(true);
    //     setPageNo(prev => prev + 1)
    //   }
    // }
  
    // useEffect(()=>{
    //   window.addEventListener("scroll", handleScroll);
  
    //   return ()=> window.removeEventListener("scroll", handleScroll)
    // },[])


    // call food menu api
    useEffect(() => {
      const fetchMenu = async () => {
        const response = await fetch(`${backendUrl}/api/menu/all`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const resData = await response.json();
        setFoodMenu(resData.data);
        // console.log(resData.data);
      };

      fetchMenu()
    },[])

  //call food types api
  useEffect(() => {
    const fetchTypes = async () => {
      const response = await fetch(`${backendUrl}/api/type/all`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = await response.json();
      setFoodType(resData.data);
      // console.log(resData.data);
    };

    fetchTypes()
  },[])

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <header className="header_section" id="hdr-section">
        <section className="header_area inner-page-header">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <NavLink to='/' className="navbar-brand">
                <img
                  src={logo}
                  alt="Logo"
                  className="d-none d-lg-inline-block"
                  width={274}
                />
                <img
                  src={logo}
                  alt="Logo"
                  className="d-lg-none mob-logo"
                  width={180}
                />
              </NavLink>
              {userProfileData.user_name ? 
                <>
                <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav ms-auto user-pro">
                    <li className="nav-item">
                      <span style={{marginRight: "10px"}}>
                        <img
                          src={userProfileData?.profile_img_url }
                          alt="profile"
                          className="img-fluid"
                        />
                      </span>
                      <div className="dropdown">
                        <button
                          className="btn btn-sm theme-bg-white dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {userProfileData?.user_name ? userProfileData?.user_name : 'User Name' }
                        </button>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li>
                            <button className="dropdown-item" onClick={()=>navigate(`/profile/${userProfileData?._id}`)}>
                              My Account
                            </button>
                          </li>
                          <li>
                            <button onClick={handleLogoutByContext}  className="dropdown-item">
                              Log Out
                            </button>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
                </>
                : 
                <>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div className="homenavbar collapse navbar-collapse" id="navbarNavDropdown">
                    <ul className="navbar-nav ms-auto log-sign" >
                      <li className="nav-item">
                        <NavLink to='/login'
                          className="nav-link theme-color"
                        >
                          Log in
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to='/signup'
                          className="nav-link theme-color"
                        >
                          Sign up
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </>
                }
            </div>
          </nav>
        </section>
      </header>
      {/* end header section */}
      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <NavLink to='/' className='navlink'>Home</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink onClick={()=>setHotel('all')} className="navlink">
               Venue
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink className="navlink theme-color">
               {hotel}
              </NavLink>
            </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Filter section */}
      <section className="filter-sec theme-bg layout_padding_2">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="filter-bar mb-3">
                <p>Filters</p>
              </div>
              <div
                className="filter-bar accordion accordion-flush"
                id="accordionFlushExample"
              >
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Destination">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Destination"
                      aria-expanded="false"
                      aria-controls="Destination"
                    >
                      Destination
                    </button>
                  </h2>
                  <div
                    id="Destination"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Destination"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Placeholder content for this accordion, which is intended
                      to demonstrate the class. This is the first item's
                      accordion body.
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Resort">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Resort"
                      aria-expanded="true"
                      aria-controls="Resort"
                    >
                      Resort
                    </button>
                  </h2>
                  <div
                    id="Resort"
                    className="accordion-collapse collapse show"
                    aria-labelledby="flush-Resort"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked"
                        >
                          Magic Kingdom
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked1"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked1"
                        >
                          Animal Kingdom
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked2"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked2"
                        >
                          Hollywood Studios
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-land">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#land"
                      aria-expanded="false"
                      aria-controls="land"
                    >
                      Land
                    </button>
                  </h2>
                  <div
                    id="land"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-land"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the third item's accordion body. Nothing more
                      exciting happening here in terms of content, but just
                      filling up the space to make it look, at least at first
                      glance, a bit more representative of how this would look
                      in a real-world application.
                    </div>
                  </div>
                </div> */}

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-PriceRange">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#PriceRange"
                      aria-expanded="false"
                      aria-controls="PriceRange"
                    >
                      Price Range
                    </button>
                  </h2>
                  <div
                    id="PriceRange"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-PriceRange"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked00"
                          onChange={() => handlePriceChange('$')}
                          checked={selectedPriceRange.includes('$')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked00"
                        >
                          $
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked11"
                          onChange={() => handlePriceChange('$$')}
                          checked={selectedPriceRange.includes('$$')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked11"
                        >
                          $$
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked22"
                          onChange={() => handlePriceChange('$$$')}
                          checked={selectedPriceRange.includes('$$$')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked22"
                        >
                        $$$
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue=""
                          id="flexCheckChecked33"
                          onChange={() => handlePriceChange('$$$$')}
                          checked={selectedPriceRange.includes('$$$$')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexCheckChecked33"
                        >
                        $$$$
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Cuisine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Cuisine"
                      aria-expanded="false"
                      aria-controls="Cuisine"
                    >
                      Cuisine
                    </button>
                  </h2>
                  <div
                    id="Cuisine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Cuisine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the third item's accordion body. Nothing more
                      exciting happening here in terms of content, but just
                      filling up the space to make it look, at least at first
                      glance, a bit more representative of how this would look
                      in a real-world application.
                    </div>
                  </div>
                </div> */}
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Type">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Type"
                      aria-expanded="false"
                      aria-controls="Type"
                    >
                      Type
                    </button>
                  </h2>
                  <div
                    id="Type"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Type"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {foodType && foodType.map( types => (
                        <div className="form-check" key={types._id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id={types.name}
                            onChange={() => handleTypeChange(types._id)}
                            checked={selectedTypes.includes(types._id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={types.name}
                          >
                            {types.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Menu">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Menu"
                      aria-expanded="false"
                      aria-controls="Menu"
                    >
                      Menu
                    </button>
                  </h2>
                  <div
                    id="Menu"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Menu"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      {foodMenu && foodMenu.map( menu => (
                        <div className="form-check" key={menu._id}>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id={menu.name}
                            onChange={() => handleMenuChange(menu._id)}
                            checked={selectedMenu.includes(menu._id)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={menu.name}
                          >
                            {menu.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                {/* <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-Services">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#Services"
                      aria-expanded="false"
                      aria-controls="Services"
                    >
                      Services
                    </button>
                  </h2>
                  <div
                    id="Services"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-Services"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      Placeholder content for this accordion, which is intended
                      to demonstrate the <code>.accordion-flush</code> class.
                      This is the third item's accordion body. Nothing more
                      exciting happening here in terms of content, but just
                      filling up the space to make it look, at least at first
                      glance, a bit more representative of how this would look
                      in a real-world application.
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-md-8">
              <div className="filter-bar mb-3">
                <div className="row text-end justify-content-end ">
                  <label
                    htmlFor="filterselect"
                    className="col-3 col-md-6 col-lg-8 col-form-label"
                  >
                    Sort by:
                  </label>
                  <div className="col-9 col-md-6 col-lg-4">
                    <select
                      className="form-select"
                      id="filterselect"
                      aria-label="Default select example"
                      onChange={(e)=>setSortRating(e.target.value)}
                    >
                      <option value='all'>Recommended</option>
                      <option value='high-low'>Rating: high-low ↓</option>
                      <option value='low-high'>Rating: low-high ↑</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row g-3 sort-cnt">
                {hotelData.length !== 0 ? ( hotelData.map((hotel)=>(
                    <div key={hotel._id} className="col-md-6 Cpointer" 
                      onClick={()=>showDetails(hotel.slug, hotel._id)}>
                        <div className="theme-bg-white p-3 h-100">
                          <figure>
                            <img
                              src={hotel?.featured_image || placeholderIMG}
                              alt="Hotel Image"
                              className="img-fluid"
                            />
                            <span className="ratting">
                              {hotel.rating} <i className="fa-solid fa-star" />
                            </span>
                          </figure>
                          <h3 className="mt-3">{hotel?.hotel_name}</h3>
                          <p className="theme-col-3 mb-1">{hotel.address}</p>
                        
                          {hotel?.description ?
                            <p className="theme-col-2 mb-1">
                              {isHTML(hotel?.description) 
                                ? parse(hotel?.description.substring(0, 70)) // Truncate before parsing
                                : `${hotel?.description?.substring(0, 70)} ...`}
                            </p>
                            : <p className="theme-col-2 mb-1">Demo Description ...</p>
                          }

                          <p className="theme-col-3">
                            <span className="theme-color">Open:</span> {hotel.opening} 
                          </p>
                        </div>
                      </div>
                  ))
                ): <p style={{textAlign : "center", marginTop: "3rem"}}>"No Restaurant Found!"</p>}
              </div>
              
              <div onClick={handleLoadData} className="read-more-btn">
                {isLoading ? "Loading Data ..." : "Load More"}
              </div>

            </div>
          </div>
        </div>
      </section>
      {/* end Filter section */}
      {/* start Filter Content section */}
      {/* end Filter Content section */}
      {/* footer section */}
      <FOOTER/>
      <ScrollToTop/>
    </div>
  );
}

export default Listing;
