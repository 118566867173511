import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import logo from "../images/logo-inner.png";
import { MyContext } from "../MyContext";

function HEADER() {
    const navigate = useNavigate();
    const { userProfileData, setUserProfileData } = useContext(MyContext);

    const handleLogoutByContext = ()=>{
        localStorage.removeItem("user_data");
        setUserProfileData([]);
        navigate("/");
    }

  return (
    <header className="header_section" id="hdr-section">
    <section className="header_area inner-page-header">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <NavLink to='/' className="navbar-brand" >
            <img
              src={logo}
              alt="Logo"
              className="d-none d-lg-inline-block"
              width={274}
            />
            <img
              src={logo}
              alt="Logo"
              className="d-lg-none mob-logo"
              width={180}
            />
          </NavLink>
          {userProfileData?.user_name ? 
            <>
            <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ms-auto user-pro">
                <li className="nav-item">
                  <span style={{marginRight: "10px"}}>
                    <img
                      src={userProfileData?.profile_img_url }
                      alt="profile"
                      className="img-fluid"
                    />
                  </span>
                  <div className="dropdown">
                    <button
                      className="btn btn-sm theme-bg-white dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {userProfileData?.user_name ? userProfileData?.user_name : 'User Name' }
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <button className="dropdown-item"  onClick={()=>navigate(`/profile/${userProfileData?._id}`)}>
                          My Account
                        </button>
                      </li>
                      <li>
                        <button onClick={handleLogoutByContext}  className="dropdown-item">
                          Log Out
                        </button>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            </>
            : 
            <>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="homenavbar collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto log-sign" >
                  <li className="nav-item">
                    <NavLink to='/login'
                      className="nav-link theme-color"
                    >
                      Log in
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to='/signup'
                      className="nav-link theme-color"
                    >
                      Sign up
                    </NavLink>
                  </li>
                </ul>
              </div>
            </>
            }
        </div>
      </nav>
    </section>
  </header>
  )
}

export default HEADER