import React, { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

import "../../css/all.css";
import "../../css/style.css";
import "../../css/responsive.css";
import "../../css/bootstrap.min.css";

import { FiAlignJustify } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import logo from "../../images/logo-inner.png";
import profile from "../../images/profile.png";
import { MyContext } from "../../MyContext";
import FOOTER from "../../component/FOOTER";

function EditProfile() {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const navigate = useNavigate();
  const { ID } = useParams();
  const { userProfileData, setUserProfileData } = useContext(MyContext)
  const [image, setImage] = useState(null);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  
  const [newUserData, setNewUserData] = useState({
    Email: userProfileData?.Email || "",
    user_name: userProfileData?.user_name || "",
    profile_img_url : userProfileData?.profile_img_url || null,
    first_name: userProfileData?.first_name || "",
    last_name: userProfileData?.last_name || "",

    birthdate:{
      month : userProfileData?.birthdate?.month || 0,
      date : userProfileData?.birthdate?.date || 0,
      year: userProfileData?.birthdate?.year || 0,
    },

    address:{
      address1: userProfileData?.address?.address1 || "",
      address2: userProfileData?.address?.address2 || "",
      city: userProfileData?.address?.city || "",
      state: userProfileData?.address?.state || "",
      zip: userProfileData?.address?.zip || 0,
      country: userProfileData?.address?.country || "",
    },

    phone_no:{
      prefix: userProfileData?.phone_no?.prefix || "",
      phone: userProfileData?.phone_no?.phone || 0,
    },

    social_media: {
      facebook: userProfileData?.social_media?.facebook || '',
      instagram: userProfileData?.social_media?.instagram || '',
      youTube: userProfileData?.social_media?.youTube || '',
      tikTok: userProfileData?.social_media?.tikTok || '',
      twitter: userProfileData?.social_media?.twitter || ''
    },
  })

  const handleImage = (e)=> {
    setNewUserData({
      ...newUserData,
      "profile_img_url": e.target.files[0],
    })

    const reader = new FileReader();
    reader.onloadend = () => {
      setImage(reader.result); // Set the image as a data URL
    };
    reader.readAsDataURL(e.target.files[0]); // Convert the file to a data URL
  }

  const handleClear = (e)=>{
    e.preventDefault();
    setNewUserData({
      Email: userProfileData?.Email || "",
      user_name: userProfileData?.user_name || "",
      profile_img_url : userProfileData?.profile_img_url || null,
      first_name: userProfileData?.first_name || "",
      last_name: userProfileData?.last_name || "",
  
      birthdate:{
        month : userProfileData?.birthdate?.month || 0,
        date : userProfileData?.birthdate?.date || 0,
        year: userProfileData?.birthdate?.year || 0,
      },
  
      address:{
        address1: userProfileData?.address?.address1 || "",
        address2: userProfileData?.address?.address2 || "",
        city: userProfileData?.address?.city || "",
        state: userProfileData?.address?.state || "",
        zip: userProfileData?.address?.zip || 0,
        country: userProfileData?.address?.country || "",
      },
  
      phone_no:{
        prefix: userProfileData?.phone_no?.prefix || "",
        phone: userProfileData?.phone_no?.phone || 0,
      },
  
      social_media: {
        facebook: userProfileData?.social_media?.facebook || '',
        instagram: userProfileData?.social_media?.instagram || '',
        youTube: userProfileData?.social_media?.youTube || '',
        tikTok: userProfileData?.social_media?.tikTok || '',
        twitter: userProfileData?.social_media?.twitter || ''
      },
    })
  }

  const formData = new FormData();

  formData.append("Email", newUserData.Email);
  formData.append("user_name", newUserData.user_name);
  formData.append("profile_img_url", newUserData.profile_img_url);
  formData.append("first_name", newUserData.first_name);
  formData.append("last_name", newUserData.last_name);

  // Append birthdate fields
  formData.append("birthdate[month]", newUserData.birthdate.month);
  formData.append("birthdate[date]", newUserData.birthdate.date);
  formData.append("birthdate[year]", newUserData.birthdate.year);

  // Append address fields
  formData.append("address[address1]", newUserData.address.address1);
  formData.append("address[address2]", newUserData.address.address2);
  formData.append("address[city]", newUserData.address.city);
  formData.append("address[state]", newUserData.address.state);
  formData.append("address[zip]", newUserData.address.zip);
  formData.append("address[country]", newUserData.address.country);

  // Append phone_no fields
  formData.append("phone_no[prefix]", newUserData.phone_no.prefix);
  formData.append("phone_no[phone]", newUserData.phone_no.phone);

  // Append social media fields
  formData.append("social_media[facebook]", newUserData.social_media.facebook);
  formData.append("social_media[instagram]", newUserData.social_media.instagram);
  formData.append("social_media[youTube]", newUserData.social_media.youTube);
  formData.append("social_media[tikTok]", newUserData.social_media.tikTok);
  formData.append("social_media[twitter]", newUserData.social_media.twitter);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
  
      const response = await fetch(`${backendUrl}/api/user/edit-profile/${ID}`, {
        method: 'PUT',
        body: formData,
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const responseData = await response.json();
      if(responseData.statusCode === 200){
        navigate(`/profile/${ID}`)
      }
    } catch (error) {
      console.error('Error during profile update:', error);
    }
  };
  


  // useEffect(()=>{
  //   const isLogin = async()=>{
  //     const response = await fetch(`${backendUrl}/api/user/cookies`,{
  //       method: 'GET',
  //       credentials: "include",
  //       headers: {
  //         "Content-Type": "application/json",
  //       }
  //     })

  //     const Allcookies = await response.json();
  //     if(Allcookies.message === 'cookies not found'){
  //       navigate('/');
  //     }
  //   }
  //   setTimeout(isLogin, 5000);
  // },[])

  const handleLogout = async (e)=>{
    e.preventDefault();
    localStorage.removeItem("user_data");
    setUserProfileData([]);
    navigate('/');

    // try {
    //   const response = await fetch(`${backendUrl}/api/user/logout`, {
    //     method: 'POST',
    //     credentials: "include",
    //     headers: {
    //       "Content-Type": "application/json",
    //     }
    //   });

    //   const responesData = await response.json();

    //   console.log(responesData)
    
    //   if (responesData.message === "User logged out") {
    //     navigate('/');
    //   }

    // } catch (error) {
    //   console.log(error)
    // }
  }

  return (
    <div style={{fontFamily:"Arimo"}}>
      {/* header section strats */}
      <header className="header_section" id="hdr-section">
        <section className="header_area inner-page-header">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <NavLink className="navbar-brand" to='/'>
                <img
                  src={logo}
                  alt="Logo"
                  className="d-none d-lg-inline-block"
                  width={274}
                />
                <img
                  src={logo}
                  alt="Logo"
                  className="d-lg-none mob-logo"
                  width={180}
                />
              </NavLink>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto user-pro">
                  <li className="nav-item">
                    <span>
                      <img
                        src={userProfileData?.profile_img_url ||  profile}
                        alt="profile"
                        className="img-fluid"
                      />
                    </span>
                    <div className="dropdown">
                      <button
                        className="btn btn-sm theme-bg-white dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {userProfileData?.user_name || "User Name"}
                      </button>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton1"
                      >
                        <li>
                          <a className="dropdown-item" onClick={()=>navigate(`/profile/${userProfileData?._id}`)}>
                            My Account
                          </a>
                        </li>
                        <li>
                          <button onClick={handleLogout} className="dropdown-item">
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </header>
      {/* end header section */}

      {/* ======= Sidebar ======= */}
      <aside
        className="sidebar"
        style={{ left: toggleSidebar ? "-300px" : "0", marginTop: "25px"}}
      >
        <ul className="sidebar-nav">
          <li className="nav-item">
            <div className="col-12 profile">
              <div className="prof-pic">
                <img
                  src={newUserData?.profile_img_url || profile}
                  alt="profile"
                  className="img-fluid"
                />
              </div>
              <div className="mt-3 mt-md-4 text-center">
                <h3 className="">
                  {newUserData?.user_name
                    ? newUserData?.user_name
                    : "User Name"}
                </h3>
              </div>
            </div>
          </li>
          <li className="nav-item mt-3">
            <NavLink to={`/profile/${ID}`} className="nav-link collapsed">
              <i className="fa-solid fa-user" />
              <span>Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/edit-profile/${ID}`} className="nav-link collapsed active">
              <i className="fa-solid fa-pen" />
              <span>Edit Profile</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to={`/favorite-food/${ID}`} className="nav-link collapsed">
              <i className="fa-solid fa-utensils" />
              <span>Favorite Food</span>
            </NavLink>
          </li>
        </ul>
      </aside>
      {/* End Sidebar*/}

      {/* Start Navigation Section */}
      <section className="navigation-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <NavLink to={`/profile/${ID}`} className='navlink'>Profile</NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink className="navlink theme-color">
                Edit Profile
              </NavLink>
              <span className="theme-col-2"> / </span>
              <NavLink to={`/favorite-food/${ID}`} className="navlink">
                Favorite Food
              </NavLink>
            </div>
            <div onClick={()=>setToggleSidebar(!toggleSidebar)}
            className="toggle-button" 
            style={{position: "absolute", cursor: "pointer", zIndex: "99",left: "0", margin: "40px 0 0 0"}}>
            {toggleSidebar ?  <FiAlignJustify fontSize={30}/> : <RxCross2 fontSize={30}/> }
          </div>
          </div>
        </div>
      </section>
      {/* End Navigation Section */}
      {/* Start Privacy section */}
      <section className="blog-sec theme-bg layout_padding_2 profileSec">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-10 col-md-8 col-lg-6">
              <div className="row">
                <div className="col-12 profile">
                  <div className="prof-pic">
                    <img
                        src={image || userProfileData?.profile_img_url || profile}
                        alt="profile"
                      className="img-fluid"
                    />
                  </div>
                  <div class="file-input-container">
                      <input onChange={(e)=> handleImage(e)}
                        type="file" id="file-input" class="file-input"/>
                      <label for="file-input" class="file-input-label">{ newUserData?.profile_img_url?.name ||  'Choose Profile'}</label>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="heading_container_4 text-center">
                    <p className="theme-col-2 px-4">
                      Must be a .jpg, .gif, or .png file smaller than 6MB and at
                      least 500x500 pixels.
                    </p>
                  </div>
                </div>
                <div className="login-form mt-3">
                  <form onSubmit={(e)=>handleSubmit(e)}>
                    <div className="row g-3">
                      <div className="col-12">
                        <label htmlFor="inputEmail4" className="form-label">
                          Email address
                        </label>
                        <input
                          value={newUserData?.Email}
                          type="email"
                          className="form-control"
                          id="inputEmail4"
                          readOnly
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="Username" className="form-label">
                          Username
                        </label>
                        <input
                          value={newUserData?.user_name}
                          type="text"
                          className="form-control"
                          id="Username"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            "user_name": e.target.value,
                          })}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="FirstName" className="form-label">
                          First Name
                        </label>
                        <input
                          value={newUserData?.first_name}
                          type="text"
                          className="form-control"
                          id="FirstName"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            "first_name": e.target.value,
                          })}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="LastName" className="form-label">
                          Last Name
                        </label>
                        <input
                          value={newUserData?.last_name}
                          type="text"
                          className="form-control"
                          id="LastName"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            "last_name": e.target.value,
                          })}
                        />
                      </div>
                      <div className="col-12 sort-cnt mb-2 mb-md-2">
                        <h4>Address</h4>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Address1" className="form-label">
                          Address 1
                        </label>
                        <input
                          value={newUserData?.address?.address1}
                          type="text"
                          className="form-control"
                          id="Address1"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "address1": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="Address2" className="form-label">
                          Address 2
                        </label>
                        <input
                          value={newUserData?.address?.address2}
                          type="text"
                          className="form-control"
                          id="Address2"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "address2": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="City" className="form-label">
                          City
                        </label>
                        <input 
                          value={newUserData?.address?.city}
                          type="text" 
                          className="form-control" 
                          id="City" 
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "city": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="State" className="form-label">
                          State
                        </label>
                        <input
                          value={newUserData?.address?.state}
                          type="text"
                          className="form-control"
                          id="State"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "state": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="Zip" className="form-label">
                          Zip
                        </label>
                        <input
                          value={newUserData?.address?.zip} 
                          type="number" 
                          className="form-control" 
                          id="Zip" 
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "zip": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="Country" className="form-label">
                          Country
                        </label>
                        <input
                          value={newUserData?.address?.country} 
                          type="text"
                          className="form-control"
                          id="Country"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            address: {
                              ...newUserData.address,
                              "country": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-12 sort-cnt mb-2 mb-md-2">
                        <h4>Phone</h4>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="Prefix" className="form-label">
                          Prefix
                        </label>
                        <input
                          value={newUserData?.phone_no?.prefix}
                          type="text"
                          className="form-control"
                          id="Prefix"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            phone_no: {
                              ...newUserData.phone_no,
                              "prefix": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-8">
                        <label htmlFor="Number" className="form-label">
                          Number
                        </label>
                        <input
                          value={newUserData?.phone_no?.phone}
                          type="number"
                          className="form-control"
                          id="Number"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            phone_no: {
                              ...newUserData.phone_no,
                              "phone": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-12 sort-cnt mb-2 mb-md-2">
                        <h4>Birthdate</h4>
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="Month" className="form-label">
                          Month
                        </label>
                        <input
                          value={newUserData?.birthdate?.month}
                          type="number"
                          className="form-control"
                          id="Month"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            birthdate: {
                              ...newUserData.birthdate,
                              "month": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="Date" className="form-label">
                          Date
                        </label>
                        <input 
                          value={newUserData?.birthdate?.date}
                          type="number" 
                          className="form-control" 
                          id="Date" 
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            birthdate: {
                              ...newUserData.birthdate,
                              "date": e.target.value,
                            }
                          })}
                        />
                      </div>
                      <div className="col-md-4">
                        <label htmlFor="Year" className="form-label">
                          Year
                        </label>
                        <input 
                          value={newUserData?.birthdate?.year}
                          type="number" 
                          className="form-control" 
                          id="Year"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            birthdate: {
                              ...newUserData.birthdate,
                              "year": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12 sort-cnt mb-2 mb-md-2">
                        <h4>Social</h4>
                      </div>
                      <div className="col-12">
                        <label htmlFor="Facebook" className="form-label">
                          Facebook
                        </label>
                        <input
                          value={newUserData?.social_media?.facebook}
                          type="url"
                          className="form-control"
                          id="Facebook"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            social_media: {
                              ...newUserData.social_media,
                              "facebook": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="Instagram" className="form-label">
                          Instagram
                        </label>
                        <input
                          value={newUserData?.social_media?.instagram}
                          type="url"
                          className="form-control"
                          id="Instagram"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            social_media: {
                              ...newUserData.social_media,
                              "instagram": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="YouTube" className="form-label">
                          YouTube
                        </label>
                        <input
                          value={newUserData?.social_media?.youTube}
                          type="url"
                          className="form-control"
                          id="YouTube"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            social_media: {
                              ...newUserData.social_media,
                              "youTube": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="TikTok" className="form-label">
                          TikTok
                        </label>
                        <input
                          value={newUserData?.social_media?.tikTok}
                          type="url"
                          className="form-control"
                          id="TikTok"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            social_media: {
                              ...newUserData.social_media,
                              "tikTok": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12">
                        <label htmlFor="Twitter" className="form-label">
                          Twitter
                        </label>
                        <input
                          value={newUserData?.social_media?.twitter}
                          type="url"
                          className="form-control"
                          id="Twitter"
                          onChange={(e)=>setNewUserData({
                            ...newUserData,
                            social_media: {
                              ...newUserData.social_media,
                              "twitter": e.target.value,
                            }
                          })} 
                        />
                      </div>
                      <div className="col-12">
                        <button className="cmn_btn btn btn-sm btn-bg" type="submit">
                          Save
                        </button>
                        <button className="cmn_btn btn btn-sm btn-bg-2 ms-2" onClick={(e)=>handleClear(e)}>
                          Discard Changes
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* end Privacy section */}
      {/* footer section */}
      <FOOTER/>
    </div>
  );
}

export default EditProfile;
